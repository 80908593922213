.footer-wrapper {
  background-color: #1a1a1a;
}

.footer-short-desc {
  line-height: 26px;
  color: #ffffff;
  font-size: 16px;
  padding: 20px 0;
}

.footer-links-title {
  color: #ffffff;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
}

.footer-links {
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  line-height: 42px;
}

.footer-get-touch {
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}

.border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}

.footer-terms {
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.links:hover {
  color: #ffffff;
}
.footer-bottom-border{
  border-bottom: 1px solid #D8D8D8;
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {

  .footer-short-desc {
    font-size: 14px;
    line-height: 21px;
  }

  .footer-terms {
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
  }

  .footer-links-responsive{
    display: flex;
    flex-wrap: wrap;
  }
  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .footer-social-icon {
    display: flex;
  }

  .footer-social-equal-icon {
    display: flex;
    justify-content: space-between;
  }

  .footer-links-responsive{
    display: flex;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .footer-social-icon{
    display: flex;
    flex-direction: column;
  }

  .footer-links-responsive{
    display: flex;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .footer-social-icon{
    display: flex;
    flex-direction: column;
  }

  .footer-links-responsive{
    display: flex;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .footer-social-icon{
    display: flex;
    flex-direction: column;
  }

  .footer-links-responsive{
    display: flex;
  }
}
