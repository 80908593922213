.blog-title {
	font-size: 24px;
	line-height: 24px;
	font-weight: 400;
	color: #757575;
}

.blog-section-subtitle {
	font-size: 18px !important;
	line-height: 27px;
	color: #757575;
	max-width: 700px;
	margin: auto;
}

.course-card-section-spacing {
	margin: 60px 0px !important;
}

/* .button {
    background-color: #eee;
    border: none;
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px;
    line-height: 27px;
  }
  
  .button-yellow {
    background-color: #ffcc00;
    color: #333;
  } */

.view-btn {
	font-size: 16px;
	cursor: pointer;
	width: auto;
	padding: 10px 20px;
	text-align: center;
	color: #000000 !important;
	font-weight: 400;
	cursor: pointer;
	display: inline-block;
	border-radius: 12px;
	line-height: 27px;
	background-color: #ffcd00 !important;
	border: 0;
	/* transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out; */
}

/* .view-btn-hover:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #1a1a1a;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.view-btn:hover {
  border: 1px solid #1a1a1a !important;
} */

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

.view-btn-hover {
	transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@keyframes bounceBack {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(5px);
	}
}

.no-course-text {
	font-size: 23px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.blog-title {
		font-size: 24px;
		line-height: 24px;
	}

	.blog-section-subtitle {
		font-size: 16px !important;
		line-height: 24px;
	}

	.no-blog-image {
		width: 70% !important;
	}

	.no-blog-text {
		font-size: 16px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media only screen and (max-width: 576px) {
	.course-card-section-spacing {
		margin: 20px 0px !important;
	}
}
