/* Font family */
@font-face {
  font-family: "IBM Plex Serif";
  src:
    local("IBMPlexSerif-Regular"),
    url("/src/fonts/IBMPlexSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Switzer";
  src:
    local("Switzer-Regular"),
    url("/src/fonts/Switzer-Regular.ttf") format("truetype");
}
