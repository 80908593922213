.course-certificate-wrapper {
  margin: 100px 0;
}

.certificate-heading {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.certificate-label {
  font-size: 18px;
  line-height: 27px;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.certificate-desc-layout {
  margin-left: 45px;
}

.certificate-name {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  color: #1a1a1a;
  margin-top: 20px;
  margin-bottom: 15px;
}

.certificate-expr {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.main-content-layout {
  margin: 40px 0;
}
@media only screen and (max-width:992px) {
  .course-certificate-wrapper {
    margin: 30px 0;
  }
  .certificate-desc-layout {
    margin-left: 0;
  }
}