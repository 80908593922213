@use "../core/variable" as var;

.offcanvas-end {
  width: 450px;
}
.Toast {
  background: white;
  border-left: 1rem solid #8a8a8a;
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: Helvetica;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem auto;
  width: 27rem;
}

.Toast--success {
  border-color: var.$success;
}
.Toast--Info {
  border-color: var.$primary;
}
.Toast--warning {
  border-color: var.$orange;
}

.Toast--error {
  border-color: var.$danger;
}

.Toast__message-category {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-transform: capitalize;
}

.Toast__message-text {
  margin-bottom: 0;
}

.Toast__button {
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  margin-left: 1rem;
  opacity: 0.5;
  padding: 0;
}

.category {
  padding: 50px 0;
  text-align: right;
}
