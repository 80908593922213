@use "../core/variable" as var;

#text-on-slider {
  position: absolute;
  height: calc(80vh);
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  z-index: 2;
  top: 0;
  // pointer-events: none;
}

.image-overlay {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease-in 0s;
}

.bg-home {
  height: 90vh;
  background-size: cover !important;
  align-self: center;
  position: relative;
  background-position: center center;
  overflow: hidden;
}

.home-wrapper:before {
  content: "";
  position: absolute;
  bottom: 10rem;
  left: 66rem;
  width: 90rem;
  height: 35rem;
  transform: rotate(115deg);
  background-color: var.$primary-active;
}

.hero-carousel-slide {
  position: relative;
}

.hero-content {
  position: absolute;
  z-index: 1;
  top: 30%;
  left: 10%;
}

.carousel-control-next:hover,
.carousel-control-prev:hover,
.carousel-control-next,
.carousel-control-prev {
  opacity: 0 !important;
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .home-wrapper:before {
    left: 30rem;
    width: 75rem;
    height: 30rem;
  }
}

@media (max-width: 768px) {
  .home-wrapper:before {
    left: 16rem;
  }
}

@media (max-width: 767px) {
  .home-wrapper:before {
    left: -5rem;
  }
}

.home-wrapper-light:before {
  content: "";
  position: absolute;
  bottom: 25rem;
  left: 50rem;
  width: 75rem;
  height: 10rem;
  transform: rotate(115deg);
  background-color: var.$primary;
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .home-wrapper-light:before {
    left: 14rem;
  }

  .hero-section-top-padding {
    padding-top: 4%;
  }
}

@media (max-width: 768px) {
  .home-wrapper-light:before {
    left: 3rem;
  }
}

@media (max-width: 767px) {
  .home-wrapper-light:before {
    left: -15rem;
  }
}

.banner {
  width: 100%;
  height: 340px;
}

// new css
// .hero-section-top-padding {
//   padding-top: 5rem !important;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url(../../../../public/media/home/new-home-img.png);
//   height: 90vh;
// }

.hero-section-top-padding {
  padding-top: 3%;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .hero-section-top-padding {
    padding-top: 18% !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .hero-content .title {
    font-size: 32px !important;
    line-height: 48px !important;
  }

  .hero-content {
    position: absolute;
    top: 5% !important;
    left: unset !important;
    width: 100% !important;
  }

  #hero-section-slider {
    height: 65vh;
  }

  #hero-section-slider .carousel-inner,
  #hero-section-slider .carousel-inner .carousel-item {
    height: 100%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .hero-content {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 5%;
    width: 40%;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .hero-section-top-padding {
    padding-top: 10%;
  }
}

// @media (max-width: 1499.99px) {
//   .hero-section-top-padding {
//     padding-top: 5%;
//   }
// }

// @media (min-width: 1500px) {
//   .hero-section-top-padding {
//     padding-top: 4%;
//   }
// }
.home-title-icon {
  height: 20px;
  width: 20px;
}

.course-card-hero-img {
  height: 200px;
  width: 100%;
  border-radius: 10px;
}

.mentor-card-image {
  height: 420px;
  width: 100%;
  border-radius: 10px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .course-card-hero-img {
    height: 225px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .course-card-hero-img {
    height: 225px !important;
  }

  .home-title-icon {
    height: 30px;
    width: 30px;
  }
}
