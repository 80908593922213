.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: white;
  border: 1px solid #ddd;
  padding: 0px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  border-radius: 12px;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-header span {
  font-size: 18px;
  line-height: 27px;
  color: #1a1a1a;
}

.close-button {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #141b34;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.popup-content a {
  color: #007bff;
  text-decoration: none;
}

.popup-content a:hover {
  text-decoration: underline;
}

.accept-button {
  background: #ffcd00;
  border: none;
  padding: 12px 24px;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 128px;
  transition: background-color 0.3s;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .popup {
    width: 300px;
    bottom: 10px;
    justify-content: center;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .partners-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    color: #1a1a1a;
  }
}
