.user-container {
	position: relative;
	display: flex;
	gap: 27px;
	justify-content: center;
	align-items: center;
}

.userNotButton {
	border: none;
	background-color: transparent !important;
}

.userProfileButton{
	border: 1px solid #757575;
	border-radius: 50%;
	/* background-color: transparent !important; */
	width: 45px;
	height: 45px;
    overflow: hidden;
	padding: 0%;        
}
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
	.user-name-text {
		font-weight: 500;
		color: black;
		font-size: 16px;
		line-height: 24px;
	}

	.user-email-text {
		font-weight: 400;
		color: #757575;
		font-size: 12px;
		line-height: 18px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 769px) {
	.user-container {
		position: relative;
		display: flex;
		gap: 27px;
		justify-content: flex-start;
		margin-left: 40px;
		align-items: center;
	}

	.user-name-text {
		font-weight: 500;
		color: black;
		font-size: 20px;
		line-height: 30px;
	}

	.user-email-text {
		font-weight: 400;
		color: #757575;
		font-size: 14px;
		line-height: 21px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
	.user-name-text {
		display: none;
	}

	.user-email-text {
		display: none;
	}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.user-name-text {
		display: none;
	}

	.user-email-text {
		display: none;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.user-name-text {
		display: none;
	}

	.user-email-text {
		display: none;
	}
}
