.cta-wrapper {
	background-color: #1a1a1a;
	background-image: url('../../../../public/media/background/cta-bg.png'); /* Add this line */
	background-size: cover;
	background-position: center;
	height: auto;
	object-fit: cover;
}

.cta-title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: #ffffff;
}

.cta-subtitle {
	font-size: 20px;
	line-height: 30px;
	color: #ffffff;
}

.cta-layout {
	padding: 36px 0px;
}

.explore-btn {
	font-size: 16px;
	cursor: pointer;
	width: auto;
	padding: 10px 20px;
	text-align: center;
	color: #000000 !important;
	font-weight: 400;
	cursor: pointer;
	display: inline-block;
	background-color: #ffcd00 !important;
	border-radius: 12px;
	line-height: 27px;
	border: 0;
	/* transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out; */
}

/* 
.explore-btn-hover:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #ffffff;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.explore-btn:hover {
  border: 1px solid #1a1a1a !important;
} */

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

.explore-btn-hover {
	transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@keyframes bounceBack {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(5px);
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.cta-title {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: #ffffff;
		text-align: center;
	}

	.cta-subtitle {
		font-size: 16px;
		line-height: 24px;
		color: #ffffff;
		text-align: center;
	}

	.explore-btn {
		font-size: 16px;
		cursor: pointer;
		width: auto;
		padding: 10px 20px;
		text-align: center;
		color: #000000 !important;
		font-weight: 400;
		cursor: pointer;
		display: inline-block;
		background-color: #ffcd00 !important;
		border-radius: 12px;
		line-height: 27px;
		border: 0;
		/* transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out; */
	}
	.cta-layout {
		padding: 15px 0px 50px 0px;
	}
	.cta-wrapper {
		margin: 0 16px 0;
		border-radius: 16px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.cta-wrapper {
		margin: 0 42px 0;
		border-radius: 16px;
	}
	.career-img {
		justify-content: center;
		align-items: center;
	}
	.div-title {
		text-align: center;
	}
	.career-img{
		justify-content: center;
		align-items: center;
	}
	.career-text{
		text-align: center;
		justify-content: center;
	}

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.cta-wrapper {
		margin: 0 42px 0;
		border-radius: 16px;
	}
	.div-title {
		text-align: left;
	}

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.cta-wrapper {
		margin: 0 15rem 0;
		border-radius: 16px;
	}
	.div-title {
		text-align: left;
	}

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

	.cta-wrapper {
		margin: 0 15rem 0;
		border-radius: 16px;
	}
	.div-title {
		text-align: left;
	}
}
