.hero-section-wrapper {
	position: relative; /* Contains the video within this section */
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 70vh;
	margin-top: 88px;
	overflow: hidden; /* Hide any overflow */
	padding: min(2vh, 2vw);
}

.hero-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1;
}

.content {
	color: white;
	text-align: center;
	padding: 20px;
}

.hero-title {
	font-size: 3vw; /* More responsive units */
	line-height: 1.2em;
	font-weight: 600;
}

.hero-subtitle {
	line-height: 1.5em;
	font-weight: 500;
}

.start-btn {
	font-size: 1.2vw; /* Responsive font size for button */
	padding: 10px 20px;
	text-align: center;
	color: #000000 !important;
	font-weight: 400;
	background-color: #ffcd00 !important;
	border-radius: 12px;
	border: 0;
}

/* Mobile devices (max-width: 576px) */
@media (max-width: 576px) {
	.hero-section-wrapper {
		min-height: 40vh; /* Adjust min-height for smaller screens */
	}

	.hero-title {
		font-size: 6vw; /* Adjust size for mobile */
	}

	.hero-subtitle {
		font-size: 4vw;
	}

	.start-btn {
		font-size: 4vw;
	}
}

/* Tablet devices */
@media (min-width: 576px) and (max-width: 768px) {
	.hero-section-wrapper {
		min-height: 50vh; /* Increase height for tablets */
	}

	.hero-title {
		font-size: 5vw; /* Responsive font size */
	}

	.hero-subtitle {
		font-size: 3vw;
	}

	.start-btn {
		font-size: 3vw;
	}
}


@media (min-width: 769px) and (max-width: 1200px) {
	.hero-section-wrapper {
		min-height: 55vh; /* Increase height for tablets */
	}
}