@use "../core/variable" as var;

.mentor-card {
  transition: all 0.5s ease;
}
.mentor-card:hover {
  box-shadow: var.$shadow-primary;
}
.mentor {
  .card-img-top {
    width: 100%;
    // height: 20vw;
    object-fit: cover;
  }
}
.about-mentor {
  .sub-title {
    max-width: 85%;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .image {
    height: 100px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .mentor {
    .card-img-top {
      width: 100%;
      // height: 50vw;
      object-fit: cover;
    }
  }
}

.mentor-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.mentor-absolute {
  margin: -130px 0 0px;
}

@media (min-width: 1024px) {
  .mentor-absolute {
    width: 800px;
  }
}

// .slick-slide {
//   height: 100% !important;
// }
