.header-wrapper {
	position: sticky;
	top: 0;
	width: 100%;
	background-color: #fffcf2;
	z-index: 11;
}

@media (max-width: 1200px) {
	.header-wrapper {
		top: 88px;
	}
}

.white-header {
	background-color: white;
	color: black;
	/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
	/* border-bottom: 1px solid #ddd; */
}

/* .black-header {
	background-color: #1a1a1a;
	color: white;
} */

/* .transparent-header {
	background-color: transparent;
	box-shadow: none;
	border-bottom: none;
} */

.header-layout {
	margin: auto;
}

.header-wrapper .navbar {
	box-shadow: none;
}

.navbar-nav {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.navbar-nav .nav-item .nav-link {
	font-size: 18px;
	font-weight: 500;
	text-decoration: none;
}

.nav-item {
	display: inline-block;
	margin-right: 40px;
	font-size: 18px;
	line-height: 27px;
}

.nav-item a {
	text-decoration: none;
	color: inherit;
	font-size: 18px;
}

@media only screen and (max-width: 992px) {
	.header-wrapper {
		margin-bottom: 0;
	}
}

.header-nav-item {
	font-size: 18px;
	/* color: #000000; */
}

.header-nav-item:hover {
	color: var(--color17);
}

.nav-item.nav-text {
	font-size: 22px;
	font-weight: 400;
	color: #000000;
}
.nav_close_btn {
	padding: 33px 21px !important;
}
/* .modal-backdrop.show {
	display: none !important;
} */

.spacingHeaderTop {
	margin-top: 88px !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
	.navbar .navbar-nav {
		display: none;
	}

	.navbar .navbar-nav {
		display: none;
	}

	.authorization {
		display: none;
	}

	.drawer-align .offcanvas-body .nav-item .nav-link {
		font-size: 16px;
		color: #000000;
	}
	.drawer-align .offcanvas-body .nav-item .nav-link:hover {
		color: #24272c !important;
		border-bottom: 2px solid #24272c !important;
	}
	.drawer-align .offcanvas-body .nav-item {
		margin: 0px 0px;
	}

	.drawer-align .offcanvas-body .nav-link {
		padding-top: 0px;
		padding-bottom: 0px;
		margin: 0px 0px;
	}

	/* .custom-toggler.navbar-toggler {
		border-color: white;
	}

	.custom-toggler .navbar-toggler-icon {
		background-image: url(../../../../public/media/hgheader/hamburger-menu.svg);
		color: white;
	} */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 769px) {
	.auth.authorization {
		margin-left: 30%;
	}

	.navbar .navbar-nav {
		display: none;
	}

	.navbar .navbar-nav {
		display: none;
	}

	.nav_close_btn {
		display: none !important;
	}

	.drawer-align .offcanvas-body .nav-item .nav-link {
		font-size: 22px;
		font-family: 'g_reg' !important;
		color: #24272c;
	}
	.drawer-align .offcanvas-body .nav-item .nav-link:hover {
		color: #24272c !important;
		border-bottom: 2px solid #24272c !important;
	}

	.nav-auth-btn {
		display: none;
	}

	.custom-toggler.navbar-toggler {
		border-color: lightgreen;
	}

	.custom-toggler .navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath stroke='rgba(0, 128, 0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10'd='M4 8h24M4 16h24M4 24h24' /%3E%3C/svg%3E");
	}

	.user-profile-icon {
		display: none;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
	.authorization {
		margin-left: 0%;
	}

	.navbar .navbar-nav {
		display: block;
	}

	.navbar .navbar-toggler {
		display: none;
	}

	.nav_close_btn {
		display: none;
	}

	.border-bottom {
		display: none;
	}

	.header-notification-icon {
		display: none;
	}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.authorization {
		margin-left: 0%;
	}

	.navbar .navbar-nav {
		display: block;
	}

	.navbar .navbar-toggler {
		display: none;
	}

	.border-bottom {
		display: none;
	}

	.header-notification-icon {
		display: none;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.authorization {
		margin-left: 0%;
	}

	.navbar .navbar-nav {
		display: block;
	}

	.navbar .navbar-toggler {
		display: none;
	}

	.nav_close_btn {
		display: none;
	}

	.border-bottom {
		display: none;
	}

	.header-notification-icon {
		display: none;
	}
}
