@use "../core/variable" as var;

.ongoing:hover {
  background-color: var.$primary;
}

.rating {
  display: inline-block;
  font-size: 24px;
}

.rating input {
  display: none;
}

.rating label {
  color: #ccc;
  cursor: pointer;
}

.rating label:hover,
.rating label:hover ~ label {
  color: var.$orange;
}

.rating input:checked ~ label {
  color: var.$orange;
}

.learning-tab {
  .react-tabs__tab-list {
    border-bottom: none;
  }
  .react-tabs__tab {
    width: 32%;
  }
  .react-tabs__tab--selected {
    border: 1px solid transparent !important;
    border-bottom: none !important;
    .ongoing {
      background-color: var.$primary;
      color: var.$white;
    }
  }
  .clickable-box {
    border-radius: 6px;
  }
  .clickable-box:hover {
    background-color: var.$primary;
    color: var.$white;
  }

  .ongoing {
    border: 1px solid var.$primary;
    border-radius: 6px;
  }
}
