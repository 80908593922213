.notification-popup {
	position: absolute;
	top: 50px;
	right: 10px;
	width: 472px;
	background: white;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	padding: 32px 24px 12px 24px;
}

.notification-popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #f2f0ef;
}

.main-noti-title {
	margin-top: 5px;
	font-size: 20px;
	line-height: 22px;
	color: #1a1a1a;
}

.notification-popup-content {
	max-height: 300px;
	overflow-y: auto;
}

.close-noti-style {
	width: 24px !important;
	height: 24px !important;
}

.notification-item {
	display: flex;
	align-items: flex-start;
	/* margin: 0 0 10px 0; */
	border-bottom: 1px solid #f2f0ef;
}

.notification-icon {
	margin-right: 10px;
}

.noti-title {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
}

.noti-content {
	font-size: 14px;
	line-height: 21px;
	color: #757575;
}

.noti-date {
	font-size: 12px;
	line-height: 14px;
	color: #757575;
}

.notification-popup-footer {
	padding: 10px;
	text-align: center;
	border-top: 1px solid #f2f0ef;
}

.notification-popup-footer a {
	font-size: 18px;
	line-height: 24px;
	color: #1a1a1a;
	/* text-decoration: none; */
}

.notification-popup-content {
	max-height: 300px;
	/* Adjust as necessary */
	overflow-y: auto;
}

.notification-popup-content.scrollable {
	max-height: 500px;
	/* Adjust as necessary */
}

.no-notifications-title {
	font-family: 'lora';
	font-weight: 700;
	font-size: 24px;
	line-height: 28.8px;
	color: var(--color16);
}

.no-notifications-desc {
	font-family: 'g_reg';
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--color1);
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.notification-popup {
		background: white;
		border-radius: 8px;
		width: 90vw;
		max-width: 80rem;
		right: -45px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
