@use "../core/variable" as var;
.courses {
  height: 270px;
  opacity: 0.6px;
  background: var.$gray-200;
  padding-top: 100px;
}
.course-btn:hover {
  background-color: var.$light;
  border-color: var.$success;
}
.course-bar-btn:hover {
  background-color: var.$light;
  border-color: var.$orange;
}
.course-badge {
  background-color: var.$light-light;
}
.course-badge1 {
  background-color: var.$light-white;
}

.course-card {
  transition: all 0.7s ease;
  .image {
    .course-tag {
      right: 15px;
      position: absolute;
      top: 10px;
      transition: all 0.7s ease;
      opacity: 0;
      .tag {
        color: var.$gray-700;
        transition: all 0.5s ease;
      }
    }
  }
  :hover {
    .image {
      .course-tag {
        opacity: 1;
        :hover {
          .tag {
            color: var.$primary;
          }
        }
      }
    }
  }
}
.course-card:hover {
  box-shadow: var.$shadow-primary;
}

.topSelling {
  .card-img-top {
    width: 100%;
    // height: 15vw;
    object-fit: fill;
  }
}
.upcoming {
  .card-img-top {
    width: 100%;
    // height: 15vw;
    object-fit: cover;
  }
}

@media only screen and (max-width: 479px) {
  .topSelling {
    .card-img-top {
      width: 100%;
      height: 50vw;
      object-fit: cover;
    }
  }

  .upcoming {
    .card-img-top {
      width: 100%;
      height: 50vw;
      object-fit: cover;
    }
  }
}

// Detail wrapper
.bg-detail {
  height: 60vh;
  background-size: cover !important;
  align-self: center;
  position: relative;
  background-position: center center;
  overflow: hidden;
}
.detail-wrapper:before {
  content: "";
  position: absolute;
  bottom: 10rem;
  left: 72rem;
  width: 76rem;
  height: 35rem;
  transform: rotate(115deg);
  background-color: rgb(176, 199, 248);
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .detail-wrapper:before {
    left: 30rem;
    width: 75rem;
    height: 30rem;
  }
}

.detail-wrapper-light:before {
  content: "";
  position: absolute;
  bottom: -11rem;
  left: 17rem;
  width: 107rem;
  height: 20rem;
  transform: rotate(115deg);
  background-color: rgb(193, 212, 253);
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .detail-wrapper-light:before {
    left: 14rem;
  }
  // .upcoming-courses {
  //   height: 320px;
  // }
}
@media (max-width: 768px) {
  .detail-wrapper-light:before {
    left: -37rem;
  }
  // .upcoming-courses {
  //   height: 500px;
  // }
  .course-absolute {
    margin: -90px 0 0px;
  }
  .detail-wrapper:before {
    left: 3rem;
    bottom: 5rem;
    left: 20rem;
  }
}
@media (width: 768px) {
  // .upcoming-courses {
  //   height: 400px;
  // }
}

.course-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
  margin: -130px 0 0px;
}

.highlights-icon {
  color: var.$orange;
}
.icon {
  color: var.$success;
}
.form-icon {
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: var.$orange-light !important;
  color: var.$orange;
  border-radius: 10px;
}
.level-icon {
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  // background-color: var.$orange-light !important;
  color: var.$orange;
  border-radius: 10px;
}
.lesson-content {
  .lesson-text {
    color: var.$gray-700;
    transition: all 0.5s ease;
  }

  .lesson-icon {
    color: var.$gray-500;
    transition: all 0.5s ease;
  }

  .text-icon {
    color: var.$gray-500;
    transition: all 0.5s ease;
  }
}

.lesson-content:hover {
  .lesson-text {
    color: var.$dark;
  }
  .lesson-icon {
    color: var.$primary;
  }
  .text-icon {
    color: var.$success;
  }
}
.lesson-accordion {
  padding: 0 !important;
  .accordion-item {
    border: none !important;
  }
  .accordion-button {
    padding: 1rem 0.45rem !important;
  }
  .accordion-body {
    padding: 1r0.45rem 0.45rem !important;
  }
}
