@use "../core/variable" as var;

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
.form-control:focus {
  border-color: var.$primary;
  box-shadow: none;
}
