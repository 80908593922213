/* Sidebar.css */
.sidebar {
	height: 100%;
}

@media all and (min-width: 1999px) {
	.sidebar {
		position: sticky;
		top: 100px;
	}
}

.sidebar-header {
	background-color: #333;
	color: black;
	text-align: center;
}
.sidebar-nav {
	list-style-type: none;
	border: 1px solid var(--color20);
	border-radius: 16px;
	padding: 0;
	background-color: #fff;
	/* position: fixed; */
	/* width: 20%; */
}

.sidebar-nav-item {
	padding: 7px 0;
}

.sidebar-nav-link {
	color: #1a1a1a;
	text-decoration: none;
	display: block;
	font-size: 16px !important;
	padding: 10px 10px;
	border-radius: 4px;
	line-height: 24px;
}
.sidebar-nav-link:hover {
	color: var(--color16);
}
.sidebar-nav-link.active {
	background-color: var(--primaryColor);
	color: var(--color16);
	border-left: 4px solid var(--color16);
}
@media only screen and (max-width: 1199px) {
	.sidebar-mobile-view {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
	}
	.sidebar-nav-link.active {
		background-color: transparent;
		border-left: none;
		border-bottom: 2px solid var(--color16);
		font-weight: 500 !important;
		font-family: 'g_mid';
	}
	.sidebar-nav-link {
		font-size: 24px !important;
		border-radius: 0;
	}
	.sidebar-nav {
		border: none;
		background-color: transparent;
		position: relative;
	}
}
@media only screen and (max-width: 576px) {
	.sidebar-mobile-view {
		width: 100%;
	}
	.sidebar-nav-link.active {
		background-color: transparent;
		border-left: none;
		border-bottom: 2px solid var(--color16);
		font-weight: 500 !important;
		font-family: 'g_mid';
		font-size: 16px !important;
	}
	.sidebar-nav-link {
		font-size: 15px !important;
		border-radius: 0;
	}
}
