@use "../core/variable" as var;

@media only screen and (min-width: 400px) and (max-width: 768px) {
  .offcanvas {
    max-width: 70%;
  }
}

@media only screen and (min-width: 350px) and (max-width: 400px) {
  .offcanvas {
    max-width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  .offcanvas {
    max-width: 90%;
  }
}

.search-container {
  form {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s;
    width: 50px;
    height: 50px;
    background: var.$white;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 5px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    border: 1px solid var.$black;
  }

  .fa {
    box-sizing: border-box;
    padding: 13px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
  }

  form:hover,
  form:valid {
    width: 200px;
    cursor: pointer;
  }

  form:hover input,
  form:valid input {
    display: block;
  }

  form:hover .fa,
  form:valid .fa {
    background: #07051a;
    color: var.$white;
  }

  a {
    display: none;
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 20px;
    color: var.$white;
    text-align: center;
    width: 100%;
  }

  form:valid a {
    display: block;
  }
}

// .search-container{
//   .container {
//     // width: 100vw;
//     // height: 100vh;

//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .searchbox {
//       position: relative;
//       display: block;
//       width: 100%;
//       max-width: 53px;
//       transition: 0.4s linear;
//       overflow: hidden;

//       .searchbox__input {
//         display: block;
//         appearance: none;
//         width: 100%;

//         border: none;
//         outline: none;
//         border-radius: 50px;

//         background-color: #24233A;

//         padding: 15px;
//         color: #24233A;
//         font-size: 20px;
//         cursor: pointer;
//         transition: 0.4s linear;

//         &::placeholder {
//           color: #24233A;
//         }
//       }

//       .searchbox__icon {
//         position: absolute;
//         right: calc(53px / 2);
//         top: 50%;
//         transform: translate(50%, -50%);

//         width: 20px;
//         height: 20px;

//         pointer-events: none;

//         path {
//           fill: #00F494;
//           transition: 0.4s linear;
//         }
//       }

//       &:focus-within {
//         max-width: 300px;

//         .searchbox__input {
//           background-color: #FFF;
//           padding-right: 50px;
//           box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.4);
//         }

//         .searchbox__icon {
//           path {
//             fill: #24233A;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1440px) {
//   .search-container {
//     display: none;
//   }
// }

#headersearch input::-webkit-search-decoration,
#headersearch input::-webkit-search-cancel-button {
  display: none;
}
#headersearch input[type="search"] {
  outline: none;
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
  float: right;
  background: var.$white
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    9px center;
  // border: 2px solid #00457e;
  padding: 9px 10px 9px 32px;
  width: 55px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
// #headersearch input[type="search"]:hover {
//   background-color: var.$white;
// }
// #headersearch input[type="search"]:focus {
//   width: 150px;
//   padding-left: 32px;
//   color: #000;
//   background-color: var.$white;
//   cursor: auto;
//   width: 130px;
//   background-color: var.$white;
//   border: 2px solid #00457e;
// }
// #headersearch input:-moz-placeholder {
//   color: transparent;
// }
// #headersearch input::-webkit-input-placeholder {
//   color: transparent;
// }

.header-search form {
  transition: all 1s;
  // width: 50px;
  // height: 50px;
  background: var.$white;
  box-sizing: border-box;
  border-radius: 25px;
}

.header-search input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.header-search .fa {
  box-sizing: border-box;
  // padding: 10px;
  // border-radius: 50%;
  color: var.$gray-700;
  text-align: center;
  transition: all 1s;
}

.header-search form:hover {
  width: 300px;
  cursor: pointer;
}

.header-search form:hover input {
  display: block;
}

.header-search form:hover .fa {
  background: #07051a;
  color: var.$white;
}

.navbar {
  transition: all 0.5s ease;
  padding: 0;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
.fixed-top {
  height: 88px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 10px 0;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  // justify-content: space-between;
}
.navbar .navbar-brand {
  line-height: 40px;
  font-size: 0;
  padding: 0;
}
.navbar-brand {
  text-decoration: none;
  white-space: nowrap;
}
.navbar .navbar-brand .l-light,
.navbar .navbar-brand .logo-light-mode {
  display: inline-block;
}

.navbar .navbar-brand .l-dark,
.navbar .navbar-brand .logo-dark-mode {
  display: none;
}

.navbar .navbar-toggler {
  padding: 0px;
  border: 2px solid var.$primary;
  font-size: 20px;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.collapse:not(.show) {
  display: none;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}
.nav-link {
  display: block;
  text-decoration: none;
  transition: all 0.5s ease;
}
// @media (min-width: 992px) {
.nav-item:hover .nav-link,
.nav-item:active .nav-link,
.nav-item.active .nav-link {
  color: var.$primary !important;
  border-bottom: 2px solid var.$primary !important;
}

.mobile-navbar-navs .nav-item:hover .nav-link,
.mobile-navbar-navs .nav-item:active .nav-link,
.mobile-navbar-navs .nav-item.active .nav-link {
  color: var.$primary !important;
  border-bottom: none !important;
}

// }
.list-inline-item {
  display: inline-block;
}
.nav-item .nav-link {
  color: #24272c !important;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  background-color: transparent;
  margin: 0px 8px;
  transition: all 0.3s ease;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 2px solid transparent;
  line-height: 40px;
}
.header .nav-item .nav_link {
  color: var(--color17) !important;
  font-size: var(--fs-18);
  border-bottom: none !important;
}
.collapse:not(.show) {
  display: none;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.input_with_icon {
  position: relative;
}
.input_with_icon .form-control {
  padding-left: 40px;
  height: 21px;
}
.input_with_icon img {
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 10px;
}

.search {
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

.search-box {
  border: 1px solid var.$light-gray;
  transition: all 0.5s ease;
  cursor: pointer;
}

.search-box i {
  position: absolute;
  left: 12px;
  top: 11px;
}
.search-box {
  input[type="search"] {
    display: inline-block;
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 1rem;
    padding-bottom: 0.2rem;
    outline: none;
    border: none;
    color: var.$gray-700;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border: 1px solid var.$gray-200;
  }
}
.search-box:hover,
.search-box:active,
.search-box.active,
.search-box:focus {
  border: 1px solid var.$primary;
  color: var.$primary;
  input[type="search"] {
    color: var.$primary;
  }
  .search-box i {
    color: var.$primary;
  }
}

[type="search"]::-webkit-search-cancel-button {
  font-size: 1rem;
  color: var.$gray-200;
  background: var.$gray-300;
}

.cart-icon {
  color: var.$gray-700;
  transition: all 0.5s ease;
}
.cart-icon:hover {
  color: var.$primary;
}
