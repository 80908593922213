.course-content-wrapper {
	margin: 100px 0;
}

.course-content-title-normal-scale {
	font-size: 28px;
	line-height: 33px;
	font-weight: 600;
	color: #1a1a1a;
	margin-bottom: 20px;
}

.course-content-title-full-scale {
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	color: #22211f;
	margin-bottom: 23px !important;
}

.course-content-label {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	max-width: 900px;
	margin-bottom: 40px;
}
.accordion-item {
	border: 1px solid #1a1a1a !important;
	margin-bottom: 15px;
	/* border-radius: 12px !important; */
}

.accordion-button {
	border-bottom: 0;
}

/* .accordion-button:not(.collapsed) {
	border-bottom: 1px dashed #1a1a1a !important;
	border-style: dashed;
} */

.ac-heading-normal {
	font-size: 18px !important;
	line-height: 27px !important;
	color: #1a1a1a;
}

.ac-heading-normal:active {
	font-size: 18px !important;
	line-height: 27px !important;
}

.ac-heading-full {
	font-size: 14px !important;
	line-height: 21px !important;
	color: #22211f;
}

.ac-heading-full:active {
	font-size: 14px !important;
	line-height: 21px !important;
}

.content-list {
	padding: 10px 0;
}

.type-btn {
	background-color: #ffe01b30 !important;
	color: #1a1a1a;
	font-size: 14px;
	line-height: 21px;
}

.selectedLesson {
	background-color: #fff9e0;
}

.disabledSelection {
	color: grey;
}

.p-10 {
	padding: 10px !important;
}
@media only screen and (max-width: 992px) {
	.course-content-wrapper {
		margin: 30px 0;
	}
}

.accordion-item-shadow {
	box-shadow: 0 0 0.5rem rgba(var(--bs-body-color-rgb),.075)!important;
}

.accordion-header .accordion-button:not(.collapsed) {
	box-sizing: border-box;
	border-bottom: dotted 1px;
	margin-bottom: 3px;
}