.small-card-height {
	display: flex;
	flex-direction: column;
	height: 410px !important;
}

.medium-card-height {
	display: flex;
	flex-direction: column;
	height: 450px !important;
}

.large-card-height {
	display: flex;
	flex-direction: column;
	height: 500px !important;
}

.auto-card-height {
	display: flex;
	flex-direction: column;
	height: 100% !important;
}

.primary-button {
	background-color: #ffcd00 !important;
}

.card-button {
	font-size: 16px;
	line-height: 18px;
	border-radius: 12px !important;
	padding: 10px 22px !important;
}
.card-information-padding {
	font-size: var(--fs-16) !important;
	padding: 10px 32px !important;
}
.card-information-detail-padding {
	font-size: var(--fs-16) !important;
	padding: 8px 32px !important;
}
.card-img {
	max-height: 170px;
}

.course-button {
	position: relative;
	padding-left: 15px !important;
}

.course-button::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	background-color: #00a507;
	border-radius: 50%;
}

.button-tag {
	border-color: #757575 !important;
	cursor: default !important;
	font-size: 12px !important;
}

.btn-outline-dark:hover {
	background-color: #ffffff !important;
	color: #1a1a1a !important;
}
.download-certificate-btn{
    font-size: var(--fs-14) !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
}
.flip-card {
	width: 100%;
	height: 100%;
	perspective: 1000px; /* Creates 3D effect */
}

.flip-card-inner {
	display: grid;
	place-items: normal; /* Centers content */
	width: 100%;
	height: 100%;
	transition: transform 0.4s;
	transform-style: preserve-3d; /* Enables 3D flipping */
}

.is-flipped {
	transform: rotateY(180deg); /* Flips the card */
}

.flip-card-front,
.flip-card-back {
	grid-area: 1 / 1 / 2 / 2; /* Overlays both sides within the grid */
	backface-visibility: hidden; /* Hides back content when flipped */
}

.flip-card-back {
	transform: rotateY(180deg); /* Makes the back face start flipped */
}
