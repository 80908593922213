.course-faqs-wrapper {
  margin-bottom: 150px;
}

.faqs-heading {
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.faqs-label {
  font-size: 18px;
  line-height: 27px;
  color: #1a1a1a;
  margin-bottom: 40px;
}

.course-faqs-que {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.course-faqs-ans {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.course-faqs-wrapper .accordion-button:not(.collapsed) {
  border-bottom: 0px !important;
}
