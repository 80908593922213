.checkmark-box {
    width: 20px;
    height: 20px;
    background-color: var(--color2); /* Yellow color */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Rounded corners */
    position: relative;
}

.checkmark {
    width: 6px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}