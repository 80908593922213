.radialProgressBar {
  border-radius: 50%;
  -webkit-transform: translate(50%, 50%);
  transform: translate(0%, 0%);
  /* width: 65px;
      height: 65px; */
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ddd;
  float: left;
}

.radialProgressBar .overlay {
  border-radius: 50%;
  /* width: 50px;
      height: 50px; */
  width: 40px;
  height: 40px;
  margin: auto;
  background: var(--color3);
  text-align: center;
}

/* Define progress colors */
.progress-success {
  --progress-f-color: var(--color7);
  --progress-color: var(--color6);
}

.progress-primary {
  --progress-f-color: var(--color4);
  --progress-color: var(--color1);
}

.progress-secondary {
  --progress-f-color: var(--color8);
  --progress-color: var(--color8);
}

.topic-success i.complete {
  color: var(--color6);
}

.topic-primary i.complete {
  color: var(--color6);
}

.topic-primary i.active {
  color: var(--color1);
}

.topic-primary:hover i:not(.completed):not(.active) {
  color: var(--color1);
  font-size: 1.5rem !important;
}

.topic-primary i {
  color: var(--color8);
}

.topic-secondary i {
  color: var(--color8);
}

.topic-success .rounded_circle {
  /* background-color: var(--color7); */
  background-color: var(--color3);
}

.topic-primary .rounded_circle {
  /* background-color: var(--color4); */
  background-color: var(--color3);
}

.topic-secondary .rounded_circle {
  background-color: var(--color3);
}

/* Rounded Circles for Different Percentages */
.progress-10-circle,
.progress-20-circle,
.progress-30-circle,
.progress-40-circle,
.progress-50-circle,
.progress-60-circle,
.progress-70-circle,
.progress-80-circle,
.progress-90-circle,
.progress-100-circle {
  width: 100px;
  /* Set your desired width */
  height: 100px;
  /* Set your desired height */
  border-radius: 50%;
  /* Makes it a circle */
}

.progress-10 {
  background: conic-gradient(
    var(--progress-color) 0% 10%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-20 {
  background: conic-gradient(
    var(--progress-color) 0% 20%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-30 {
  background: conic-gradient(
    var(--progress-color) 0% 30%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-40 {
  background: conic-gradient(
    var(--progress-color) 0% 40%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-50 {
  background: conic-gradient(
    var(--progress-color) 0% 50%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-60 {
  background: conic-gradient(
    var(--progress-color) 0% 60%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-70 {
  background: conic-gradient(
    var(--progress-color) 0% 70%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-80 {
  background: conic-gradient(
    var(--progress-color) 0% 80%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-90 {
  background: conic-gradient(
    var(--progress-color) 0% 90%,
    var(--progress-f-color) 0% 0%
  );
}

.progress-100 {
  background: conic-gradient(
    var(--progress-color) 0% 100%,
    var(--progress-f-color) 0% 0%
  );
}

.accordion_button .radialProgressBar .overlay {
  background-color: var(--progress-color);
  color: var(--defualt-white);
  border: 4px solid var(--defualt-white);
}

.accordion_button:focus {
  box-shadow: none;
}

.accordion_button,
.accordion_button:not(.collapsed) {
  background-color: var(--transparent) !important;
}

.accordion_button .radialProgressBar {
  width: 50px;
  height: 50px;
}

.accordion_button .radialProgressBar .overlay {
  width: 40px;
  height: 40px;
}

/* video css */
.video-js {
  height: 30rem;
}

.video-js .vjs-load-progress {
  background-color: var(--defualt-white);
}

.video-js .vjs-play-progress {
  background-color: var(--color1);
}

.video-js .vjs-control-bar {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

/* topic details */
.details-topic div {
  max-height: 7rem;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.details-topic div.collapsed {
  max-height: none;
}

.nav_link.active .nav_topic_title {
  font-weight: 700 !important;
}

.nav_link {
  text-align: start;
  padding: unset !important;
}

.nav_link.active {
  background-color: unset !important;
}

.mr-2 {
  margin-right: 2px;
}
