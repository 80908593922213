.image-hero-section-wrapper {
  position: relative;
  overflow: hidden;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 0;
}

.bottom-layout {
  margin-bottom: -110px;
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
}

.detail-heading {
  font-weight: 600;
  font-size: var(--fs-48);
  line-height: 72px;
  margin-bottom: 25px;
}

.detail-label {
  font-size: var(--fs-20);
  line-height: 30px;
  font-weight: 500;
}

.content .dp-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.content .review-number-detail {
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.coursedetail-card {
  top: 110px;
  right: 8.5vw;
  width: 24vw !important;
  max-width: 420px !important;
  min-width: 250px !important;
  position: fixed;
  border: 1px solid rgba(213, 209, 204, 1) !important;
  border-radius: 8px;
}

.detail-inner-desc-layout {
  margin: 10px 10px;
}

.card-sticky-content {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.between-space {
  margin-right: 15px;
}

.pricing-layout {
  margin: 10px 15px;
}

.card-detail-sub-discount-price {
  font-size: 24px;
  line-height: 29px;
  color: #1a1a1a;
  font-weight: 600;
}

.card-detail-sub-actual-price {
  color: #757575;
  font-size: 16px;
  line-height: 21px;
  margin: 8px 0 10px 10px;
}

.card-border-bottom-style {
  border: 1px solid #d5d1cc !important;
  border-style: dashed !important;
  margin-top: 10px;
  margin: 5px 15px 15px 15px;
}

.card-enroll-btn {
  margin-bottom: 4px;
  font-size: 18px;
  cursor: pointer;
  width: 18%;
  padding: 12px 20px;
  text-align: center;
  color: #000000 !important;
  display: inline-block;
  background-color: #ffcd00 !important;
  border-radius: 12px;
  line-height: 27px;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}
/* 
.card-enroll-btn:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #000000;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.card-enroll-btn.card-enroll-btn-hover:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #000000;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.card-enroll-btn.card-enroll-btn-hover {
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
} */

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes bounceBack {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

.card-inner-layout {
  margin: 0 0 22px 10px;
}

.card-img-top {
  height: auto;
}

.star-detail {
  color: #ffe01b;
}

.detail-discount-price {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.detail-actual-price {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.enroll-btn {
  font-size: 18px;
  cursor: pointer;
  padding: 15px 20px;
  text-align: center;
  color: #000000 !important;
  display: inline-block;
  background-color: #ffcd00 !important;
  border-radius: 12px;
  line-height: 27px;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}
/* 
.enroll-btn:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #ffffff;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.enroll-btn.enroll-btn-hover:hover {
  background-color: #ffcd00 !important;
  box-shadow: 1px 8px #ffffff;
  color: #1a1a1a !important;
  animation: bounce 0.3s ease-in-out forwards;
}

.enroll-btn.enroll-btn-hover {
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
} */

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  background-color: #1a1a1a !important;
}

.video-wrapper {
  position: relative;
}

.video-play-button1 {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ffe01b;
  border-radius: 50%;
  cursor: pointer;
}

.video-play-button1 span {
  display: block;
  width: 0;
  height: 0;
  border-left: 19px solid #fff;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}
.pop-over-video{
  object-fit: cover;
  height: 230px;
}

.best-seller-badge {
  background-color: var(--primaryColor) !important;
  color: white;
  padding: 0.8rem 1.3rem;
}

.course-mode-badge {
  background-color: var(--color2) !important;
  color: black;
  padding: 0.8rem 1.3rem;
}

.video-controls {
  position: absolute;
  top: 180px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.video-controls button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}