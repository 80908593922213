.nav_dash_item{
  margin-right: 0;
} 
.nav_link {
	color: #757575 !important;
	line-height: 21px;
	font-size: 14px;
}

.nav-style {
	border: none;
	color: #757575 !important;
	line-height: 21px;
	font-size: 14px;
	padding: 8px 24px;
	border-radius: 60px;
	background-color: white;
}

.nav-style.active {
	background-color: #ffcd00;
	border: none;
	color: #1a1a1a !important;
	line-height: 21px;
	font-size: 14px;
	padding: 10px 24px;
	border-radius: 60px;
}
