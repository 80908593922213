.course-testimonials-wrapper {
  margin: 0 0 100px 0;
}

.testimonials-heading {
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.testimonials-label {
  font-size: 18px;
  line-height: 27px;
  color: #1a1a1a;
  margin-bottom: 40px;
}

.testimonials-list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 0px;
  margin-top: 35px;
}

.testimonials-item {
  display: flex;
  align-items: center;
  border: 1px solid #757575 !important;
  border-radius: 8px;
  padding: 5px;
  box-sizing: border-box;
}

.testimonial-profile-name {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 5px;
}

.testimonials-main-desc {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a !important;
  margin-top: 25px;
}

.testimonial-profile-label {
  font-size: 12px;
  line-height: 18px;
  color: #1a1a1a;
}
