.link-breadcrumb {
  color: #757575;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  margin-top: 3px;
}

.link-breadcrumb:hover {
  color: #757575 !important;
  text-decoration: none;
}

.breadcrumbs-title {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  margin-top: 0px;
}
