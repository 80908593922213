$spaces: "5px", "10px", "15px", "20px"; // Define the space values you want

@each $space in $spaces {
  .empty-space-#{$space} {
    height: $space; // Use whatever property you need
  }
}

.blackBorder {
  border: 1px solid #000 !important;
}

.platform-location-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-resize {
  resize: none;
}

@media only screen and (max-width: 1600px) {
  .courseDetailsMediaQuery {
    width: 60% !important;
  }
}

@media (max-width: 576px) {
  .smallImageWidth {
    width: 250px;
  }
}