.header-tag {
  display: flex;
  padding: 6px 12px;
  align-items: flex-start;
  background-color: #fff;
  color: #002350;
  border-radius: 8px;
  width: auto;
}

.tag-on-slider {
  position: absolute;
  height: 42vh;
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  z-index: 2;
  top: 0;
  /* pointer-events: none; */
}

.btn-sideborder-rounded {
  border-radius: 75px !important;
}

.banner {
  padding-left: 10%;
  padding-top: 5%;
}

.inline-style {
  color: "#3C414B";
  /* font-size: 99px; */
}

.btn-style {
  font-size: 20px;
  background-color: white;
  color: #3c78f0;
  border-radius: 4px;
  font-weight: 500;
}

.btn-style:hover {
  /* font-size: 20px; */
  background-color: white;
  color: #3c78f0;
  border-radius: 4px;
}
