.progress-bar-container {
    width: 100%;
}

.percentage-text {
    font-size: 14px;
    color: #757575;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f5f5f5 !important;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #fbc02d;
    border-radius: 5px;
    transition: width 0.4s ease;
}
