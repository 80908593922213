.heading-title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #ffffff;
}

.privacy-desc {
  font-size: 18px;
  line-height: 27px;
  color: #f1f1f1;
}

/* .terms-container {
  margin-bottom: 50px !important;

} */

.info-layout {
  background-color: #1a1a1a;
  background-image: url("../../../../public/media/background/privacy-bg.png");
  background-size: cover;
  background-position: center;
  height: auto;
  object-fit: cover;
}

.info-wrapper {
  padding: 70px 0px;
  background-color: #1a1a1a;
  background-image: url("../../../../public/media/background/privacy-bg.png");
  background-size: cover;
  background-position: center;
  height: auto;
  object-fit: cover;
}

.desc-title {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  color: #1a1a1a;
}

ol > li > strong {
  font-size: 16px;
  line-height: 27px;
  color: #1a1a1a;
  font-weight: 700;
}

.custom-class{
  background-color: var(--color19) !important;
  /* border-top: 1px solid #f1f1f1; */
  border-bottom: 1px solid #f1f1f1;
  border-color: #f1f1f1 !important;
  font-size: 24px !important;
  line-height: 29px !important ;
  color: var(--color16) !important;
  font-family: "g_mid";
}

.custom-class:active{
  background-color: var(--color19) !important;
  /* border-top: 1px solid #f1f1f1; */
  border-bottom: 1px solid #f1f1f1;
  border-color: #f1f1f1 !important;
  font-size: 24px !important;
  line-height: 29px !important ;
  color: var(--color16) !important;
  font-family: "g_mid";
}

.custom-class:not(.collapsed){
  border-bottom: none !important;
}

.accordion__item{
  border: none !important;
  background-color: var(--color19) !important
}

.accordion__item:active{
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.acc-desc{
  color: var(--color16);
  font-family: "g_reg";
}


.section-heading {
  margin-top: 20px;
  color: var(--color16);
  font-family: "g_mid";
}

.section-paragraph {
  margin-bottom: 15px;
  color: var(--color16);
  font-family: "g_reg";
}

.section-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 15px;
}

.section-list-item {
  margin-bottom: 10px;
  color: var(--color16);
  font-family: "g_reg";
}

.section-list-item strong {
  color: var(--color16);
  font-family: "g_reg";
}

.accordion-button.custom-class {
  padding: 16px 0 !important;
}