.our-course-wrapper {
	margin: 80px 0;
	border: #1a1a1a;
}

.course-section-title {
	font-size: 40px;
	line-height: 48px;
	color: #1a1a1a;
	font-weight: 700;
}

.course-section-subtitle {
	font-size: 18px;
	line-height: 27px;
	color: #757575;
	max-width: 600px;
	margin: auto;
}

.course-card-section-spacing {
	margin: 60px 0px !important;
}

.no-course-text {
	font-size: 23px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.course-section-title {
		font-size: 24px;
		line-height: 48px;
		color: #1a1a1a;
		font-weight: 700;
	}

	.course-section-subtitle {
		font-size: 16px;
		line-height: 27px;
		color: #757575;
		max-width: 600px;
		margin: auto;
	}

	.no-course-image {
		width: 70% !important;
	}

	.no-course-text {
		font-size: 15px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.course-section-title {
		font-size: 40px;
		line-height: 48px;
		color: #1a1a1a;
		font-weight: 700;
	}

	.course-section-subtitle {
		font-size: 18px;
		line-height: 27px;
		color: #757575;
		max-width: 600px;
		margin: auto;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media only screen and (max-width: 576px) {
	.our-course-wrapper {
		margin: 10px 0;
	}
}
