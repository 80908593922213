.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button-tag1 {
  border: 1px solid #757575 !important;
  font-size: 12px !important;
  line-height: 18px;
  padding: 8px 18px !important;
  font-weight: 400;
}

.blog-card-title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #1a1a1a;
}

.blog-card-desc {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.blog-border-style {
  border-bottom: 1px solid #aaaaaa;
}

.blog-link {
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.blog-link:hover {
  color: #1a1a1a;
}
.blog-img {
  height: 268px !important;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
