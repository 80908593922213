.payment-status {
  font-size: 32px;
  line-height: 39px;
  color: #1a1a1a;
  font-weight: 600;
  margin-bottom: 20px;
}

.payment-desc {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.highlight-text {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.payment-layout {
  width: 600px;
}

.layout-success {
  margin: 35px 0 0 0;
}

.myCourse-btn {
  font-size: 16px;
  cursor: pointer;
  width: 18%;
  padding: 12px 20px;
  text-align: center;
  color: #1a1a1a !important;
  display: inline-block;
  background-color: #ffcd00 !important;
  border-radius: 12px;
  line-height: 24px;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    background-color: #ffcd00 !important;
    box-shadow: 1px 8px #000000;
    color: #1a1a1a !important;
    animation: bounce 0.3s ease-in-out forwards;
  }

  &.myCourse-btn-hover:hover {
    background-color: #ffcd00 !important;
    box-shadow: 1px 8px #000000;
    color: #1a1a1a !important;
    animation: bounce 0.3s ease-in-out forwards;
  }

  &.myCourse-btn-hover {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes bounceBack {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

.back-btn {
  font-size: 16px;
  cursor: pointer;
  width: 18%;
  padding: 12px 20px;
  text-align: center;
  color: #757575 !important;
  display: inline-block;
  background-color: #ffffff !important;
  border-color: #757575 !important;
  border-radius: 12px;
  line-height: 24px;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    /* background-color: #ffcd00 !important; */
    box-shadow: 1px 8px #000000;
    border-color: #757575 !important;

    color: #757575 !important;
    animation: bounce 0.3s ease-in-out forwards;
  }

  &.back-btn-hover:hover {
    /* background-color: #ffcd00 !important; */
    box-shadow: 1px 8px #000000;
    color: #757575 !important;
    animation: bounce 0.3s ease-in-out forwards;
  }

  &.back-btn-hover {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes bounceBack {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}
