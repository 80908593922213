.partners-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #1a1a1a;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee-content {
  display: inline-block;
  animation: marquee 10s linear infinite;
  color: #1a1a1a;
}

.logo-spacing {
  padding: 0px 50px;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .partners-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    color: #1a1a1a;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .partners-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #1a1a1a;
  }
  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  
}
