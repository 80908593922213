.discover-wrapper {
  margin: 80px 0;
}

.discover-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #241c15;
}

.discover-subtitle {
  font-size: 18px;
  line-height: 27px;
  color: #757575;
  max-width: 870px;
  margin: auto;
}

.video-container {
  background-image: url("../../../../public/media/background/discover.png");
  background-size: cover;
  background-position: initial;
  height: auto;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px !important;
  position: relative; /* Add this to make the overlay absolute */
  width: 100%;
  max-width: 900px; /* Max width based on your design */
  overflow: hidden;
}

.video-container video {
  max-width: 100%;
  height: auto;
}

.radius-style {
  border: 5px solid #0f172a;
  border-radius: 22px;
  z-index: 9 !important;
}

.video-overlay1 {
  position: absolute;
  width: 99%;
  max-width: 1050px;
  height: 81%;
  background-color: #241c15;
  opacity: 1;
  z-index: 1;
  border-radius: 60px;
}

@media all and (max-width: 900px) {
  .video-overlay1 {
    border-radius: 20px;
  }
}

.discover-detail-layout {
  margin-top: 70px !important;
  /* display: flex; */
}

.discover-detail-heading {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 20px;
}

.discover-detail-subtitle {
  font-size: 18px;
  line-height: 27px;
  color: #757575;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .discover-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #241c15;
  }

  .discover-subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    max-width: 870px;
    margin: auto;
  }

  .discover-detail-layout {
    margin-top: 70px !important;
    justify-content: center;
  }

  .discover-detail-heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 20px;
  }
  
  .discover-detail-subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #757575;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .discover-detail-layout {
    margin-top: 70px !important;
    justify-content: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

}
.video-container video {
  width: 100%;
  max-height: 506px;
  max-width: 892px;
  display: block;
}
.video-container video[poster] {
  object-fit: contain;
  border: 0;
}