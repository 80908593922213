.resend-btn {
	font-family: 'g_mid';
	padding: 0 !important;
	font-size: var(--fs-12) !important;
}
.resend-btn:hover {
	color: var(--color16);
}
.upload-label {
	background-color: var(--color16);
	color: #fff;
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 12px;
	cursor: pointer;
}
@media only screen and (max-width: 420px) {
	.profile-change-btn {
		flex-direction: column;
		gap: 10px;
	}
}

.dropdown-menu {
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
}