.commonSlider {
  .slick-arrow {
    z-index: 2;
  }

  .slick-prev {
    left: -50px
  }

  .slick-next {
    right: -30px;
  }

  .slick-slider > .slick-list > .slick-track {
    display: flex;
    gap: .5rem !important;
  }
  .slick-slider {
    width: 100%;
    max-width: 90vw;
  }
}

.topRightButtons {
  top: 0;
  position: absolute;
  .next {
    right: 0
  }
  .prev {
    right: 10px
  }
}

@media (max-width: 576px) {
	.best-section-title {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: #ffffff;
	}

	.number-style {
		font-size: 40px;
		line-height: 48px;
	}
	.best-wrapper {
		margin: 0 16px 0;
		border-radius: 16px;
	}
	.number-desc {
		font-size: var(--fs-16);
	}
}
