.overview-title {
	font-size: 24px;
	line-height: 29px;
	font-weight: 700;
	color: #1a1a1a;
	margin-bottom: 15px;
}

.overview-label {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
}

.course-desc-title {
	font-size: 24px;
	line-height: 29px;
	font-weight: 600;
	color: #1a1a1a;
	margin-top: 50px;
	margin-bottom: 15px;
}

.course-desc-label {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
	margin-bottom: 30px;
}

.pre-title {
	font-size: 24px;
	line-height: 29px;
	font-weight: 600;
	color: #1a1a1a;
	margin-bottom: 15px;
	margin-top: 50px;
}

.pre-label {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
}

.objective-label {
	line-height: 2;
}

.objective-label a {
	font-size: 16px;
	color: #1a1a1a !important;
}

.btn-custom-style {
	border: 1px solid #ffe01b !important;
	border-radius: 75px !important;
	padding: 12px 24px !important;
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	margin-right: 20px;
}

.btn-custom-style:hover {
	border: 1px solid #ffe01b;
}
@media only screen and (max-width: 992px) {
	.course-overview-wrapper {
		margin: 0;
	}
	.course-desc-label {
		color: var(--color17);
		margin-bottom: 10px;
	}
	.pre-label {
		color: var(--color17);
	}
  .objective-label{
    color: var(--color17);
  }
}
