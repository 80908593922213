.pagination {
  margin: 20px 0;
}

.page-item {
  margin: 0 5px;
}

.page-item .page-link {
  color: #000000;
  background-color: transparent;
  border: 1px solid rgb(244, 244, 244);
  padding: 10px 15px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.page-item.active .page-link {
  color: #000000;
  background-color: #fecc00 !important;
  border-color: #000000;
}

.page-item.disabled .page-link {
  color: #000000;
  pointer-events: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link {
  outline: none;
  box-shadow: none;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Specific styles for Previous and Next buttons */
.page-item:first-child .page-link, /* Previous button */
  .page-item:last-child .page-link {
  /* Next button */
  border-radius: 5px; /* Make Previous and Next buttons square */
  padding: 10px 15px;
  background-color: transparent;
  color: #000000;
  border: 1px solid rgb(244, 244, 244);
}

.page-item:first-child.active .page-link,
.page-item:last-child.active .page-link {
  background-color: #fecc00;
  color: #ff0000;
  border-color: rgb(244, 244, 244);
}
