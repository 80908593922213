.fg-title {
  font-size: 24px;
  line-height: 29px;
  color: #22211f;
  font-weight: 700;
  margin: 20px 0;
}

.fg-label {
  font-size: 16px;
  line-height: 24px;
  color: #888480;
  max-width: 440px;
  margin-bottom: -10px;
}

.new-btn-fg-layout {
  padding: 14px 16px !important;
  border-radius: 12px !important;
  color: #1a1a1a;
  font-size: 16px !important;
  line-height: 24px;
  margin-top: -10px;
  width: 215px;
}

.new-btn-fg-cancel-layout {
  padding: 14px 16px !important;
  border-radius: 12px !important;
  border: 1px solid #d8d8d8 !important;
  color: #757575 !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-top: -10px;
  width: 215px;
}
