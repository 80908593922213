.base-btn-style {
	font-size: 18px;
	border-radius: 12px;
}
.base-btn-border {
	border: 1px solid var(--black);
}
.primary-btn {
	background-color: var(--color2);
	color: var(--black);
}

.secondary-btn {
	background: var(--secondaryColor);
	color: var(--primaryColor);
}

.white-btn {
	background-color: initial !important;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	line-height: 27px;
	text-align: center;
}
.grey-btn {
	color: var(--color17);
	background: white;
}
/* .primary-btn:hover,
.secondary-btn:hover,
.white-btn:hover {
	opacity: 0.9;
} */
