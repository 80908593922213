.searchicon {
  margin-left: 210px !important;
}

.nav_tabs {
  border-bottom: 0;
}
/* .nav_tabs .nav_links:hover, */
.nav_tabs .nav_links:focus,
.nav_tabs .nav_links.active {
  background-color: var(--color15);
  border-radius: 50px;
}

.nav_tabs .nav_links {
  border-bottom: none;
  color: var(--color17) !important;
}
li.nav-item.nav_item:hover {
  color: #000 !important;
}
.nav_links.active {
  color: #22211f !important;
}

.nav_links {
  color: #d5d1cc !important;
  padding: 20px 35px;
  font-size: 14px;
  line-height: 20px;
}
