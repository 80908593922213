.course-highlights-wrapper {
	margin: 50px 0 20px 0;
}

.highlights-title {
	font-size: 28px;
	line-height: 33px;
	font-weight: 600;
	color: #1a1a1a;
	margin-bottom: 20px;
}

.highlights-label {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	max-width: 900px;
}

.feature-list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px 40px;
	margin-top: 35px;
}

.feature-item {
	display: flex;
	align-items: center;
	width: calc(50% - 20px);
	box-sizing: border-box;
}

.feature-icon {
	color: #ffcc00;
	font-size: 24px;
	margin-right: 8px;
}

.feature-text {
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
	padding-top: 5px;
}
@media only screen and (max-width: 992px) {
  .course-highlights-wrapper {
    margin:0;
  }
	.highlights-label {
		font-size: 16px;
		line-height: 21px;
		color: var(--color17);
		max-width: 900px;
	}
}
@media only screen and (max-width: 576px) {
  .feature-list {
    gap: 20px;
    margin-top: 35px;
  }
	.feature-item {
		width: 100%;
	}
}

@media only screen and (max-width: 1600px) {
	.courseHighlightsMediaQuery {
		flex : 0 0 auto;
		width: 65% !important;
	}
}