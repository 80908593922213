.about-info-title {
  font-size: 32px;
  line-height: 48px;
  font-family: "g_mid";
  color: var(--color22);
}

.about-info-subtitle {
  font-size: 16px;
  line-height: 24px;
  font-family: "g_reg";
  color: var(--color22);
}

.info-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--color23);
  font-family: "g_mid";
  margin-bottom: 5px;
}

.info-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: var(--color23);
  font-family: "g_reg";
}

.form-title {
  font-size: 24px;
  line-height: 36px;
  color: var(--color22);
  font-family: "g_mid";
}

.form-label {
  font-size: 14px;
  line-height: 21px;
  color: var(--color16);
  font-family: "g_reg";

}

.contact-form .form-control {
  width: 100%;
  padding: 12px;
  margin-bottom: 25px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
}

.contact-form .btn-submit {
  background-color: #FFCD00;
  border: 1px solid #1a1a1a;
  padding: 14px 64px;
  font-size: 16px;
  border-radius: 8px;
  color: var(--color16);
  font-family: "g_mid"
}

.contact-form .btn-submit:hover {
  background-color: #e0a800;
}

.form-wrapper {
  background-color: var(--white);
  padding: 42px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: red;
  font-size: 12px;
  margin: -14px 0 10px 0;
}

.custom-heading-style {
  font-size: 48px;
  line-height: 52px;
  color: var(--white)
}

.custom-description-style {
  font-size: 24px;
  line-height: 27px;
  color: var(--white)
}

.custom-title {
  font-size: 32px;
  line-height: 39px;
  color: #F1F1F1;
}

.custom-best-wrapper {
  border-radius: 16px;
  margin: 80px 0;
}

.our-team-wrapper {
  background-color: var(--white);
}

.our-team-title {
  font-size: 40px;
  line-height: 60px;
  color: #0B0D0E;
  font-family: "g_mid";
}

.our-team-subtitle {
  font-size: 18px;
  line-height: 27px;
  color: #5C5F6E;
  font-family: "g_reg";
}

.team-img-style {
  padding: 16px;
  border-radius: 64px;
  background-color: #ECECFE;
}

.team-card-title {
  font-size: 24px;
  line-height: 36px;
  font-family: "g_mid";
  color: #0B0D0E;
}

.team-card-desc {
  font-size: 18px;
  line-height: 24px;
  font-family: "g_reg";
  color: #838696;
}

.team-btn {
  background-color: var(--primaryColor);
  color: var(--white);
  padding: 10px 25px !important;
  border-radius: 40px !important;
}

.team-btn:hover {
  background-color: var(--primaryColor);
  color: var(--white);
  padding: 10px 25px !important;
  border-radius: 40px !important;
}

.team-card-wrapper {
  border-radius: 12px;
}

.trusted-class {
  font-size: 32px;
  line-height: 48px;
  color: var(--color16);
  font-weight: 600;
  text-align: center;
}

.contact-faq {
  font-size: 40px;
  line-height: 48px;
  font-family: "g_semi";
  color: var(--color16);
  text-align: center;
}

.contact-subtitle {
  display: none
}

.contact-faq-wrapper {
  background-color: var(--white);
}

/* Adjust title font size for tablets and smaller screens */
@media (max-width: 768px) {}

/* Adjust title font size for mobile screens */
@media (max-width: 576px) {
  .team-card-title {
    font-size: 20px;
    line-height: 24px;
  }

  .custom-title {
    font-size: 24px;
    line-height: 29px;
  }

  .custom-description-style {
    font-size: 16px;
    line-height: 18px;
  }

  .custom-heading-style {
    font-size: 40px;
    line-height: 48px;
  }

  .trusted-class {
    font-size: 24px;
    line-height: 28px;
  }

  .about-info-title {
    font-size: 24px;
    line-height: 29px;
  }

  .form-title {
    font-size: 20px;
    line-height: 30px;
  }

  .our-team-title {
    font-size: 24px;
    line-height: 29px;
  }

  .contact-faq {
    font-size: 24px;
    line-height: 29px;
    margin: auto;
  }
}