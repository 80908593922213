@use "../core/variable" as var;

.seminar {
  padding-top: 100px;
  height: 270px;
  opacity: 0.6px;
}

.recommend {
  a {
    text-decoration: none;
  }
  margin-top: 50px;
  width: 100%;
  gap: 24px;
}

.allSeminar {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 150px;
}

// @media only screen and (min-width: 320px) and (max-width: 768px) {
//   .allSeminar [class*="col-"] {
//     .img {
//       width: 100%;
//       height: 94.5px;
//       margin-left: 0px;
//     }
//   }
//   .recommend {
//     .card {
//       margin-top: 20px;
//     }
//   }
// }
