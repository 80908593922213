@use "../core/variable" as var;

.about-card:hover {
  box-shadow: var.$shadow-primary !important;
  transition: all 0.5s ease;
  transform: translateY(-2px);
}
.about-image {
  height: 100px;
}

// .nav-tabs .nav-link:focus,
// .nav-tabs .nav-link:hover {
//   background: transparent !important;
//   // color: white !important;
//   border-color: none !important;
//   border-bottom: 2px solid yellow !important;
//   border-radius: 0px !important;
// }
// a:hover,
// a:focus {
//   text-decoration: none;
//   outline: none;
// }
// .tab .nav-tabs {
//   border: none;
// }
// .tab .nav-tabs li a {
//   color: #555;
//   background: #fff;
//   font-size: 19px;
//   font-weight: 600;
//   text-transform: capitalize;
//   padding: 12px 20px 14px;
//   margin: 0;
//   border: none;
//   border-radius: 0;
//   overflow: hidden;
//   position: relative;
//   z-index: 1;
//   transition: all 0.3s ease 0.3s;
// }
// .tab .nav-tabs li.active a,
// .tab .nav-tabs li a:hover,
// .tab .nav-tabs li.active a:hover {
//   color: #1d627c;
//   background: #fff;
//   border: none;
// }
// .tab .nav-tabs li a:before,
// .tab .nav-tabs li a:after {
//   content: "";
//   background-color: rgba(29, 98, 124, 0.09);
//   width: 100%;
//   height: 80%;
//   position: absolute;
//   top: -80%;
//   left: 0;
//   z-index: -1;
//   clip-path: polygon(0 0, 100% 0, 50% 100%);
//   transition: all 0.3s ease 0s;
// }
// .tab .nav-tabs li a:after {
//   background-color: #1d627c;
//   top: auto;
//   bottom: -4px;
//   height: 4px;
//   clip-path: none;
// }
// .tab .nav-tabs li.active a:before,
// .tab .nav-tabs li a:hover:before {
//   top: 0;
// }
// .tab .nav-tabs li.active a:after,
// .tab .nav-tabs li a:hover:after {
//   bottom: 0;
// }
// .tab .tab-content {
//   color: #555;
//   background: #fff;
//   font-size: 16px;
//   letter-spacing: 1px;
//   line-height: 25px;
//   padding: 20px;
//   border-bottom: 4px solid #1d627c;
//   box-shadow: 5px 5px 10px #cdcdcc;
//   position: relative;
// }
// @media only screen and (max-width: 479px) {
//   .tab .nav-tabs {
//     margin: 0 0 10px;
//   }
//   .tab .nav-tabs li {
//     width: 100%;
//     text-align: center;
//   }
// }
