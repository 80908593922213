@use "../core/variable" as var;

.webinar {
  height: 270px;
  opacity: 0.6px;
  background: var.$gray-200;
  padding-top: 100px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .webinar {
    width: 100%;
  }
}

.webinar-btn:hover {
  background-color: var.$light;
  border-color: var.$success;
}
.webinar-bar-btn:hover {
  background-color: var.$light;
  border-color: var.$orange;
}

.webinar-card {
  transition: all 0.7s ease;
  .webinar-badge {
    background-color: var.$light-light;
  }
  .image {
    .webinar-tag {
      right: 15px;
      position: absolute;
      top: 10px;
      transition: all 0.7s ease;
      opacity: 0;
      .tag {
        color: var.$gray-700;
        transition: all 0.5s ease;
      }
    }
  }
  :hover {
    .image {
      .webinar-tag {
        opacity: 1;
        :hover {
          .tag {
            color: var.$primary;
          }
        }
      }
    }
  }
}
.react-tabs__tab--selected {
  color: var.$primary !important;
  border: 1px solid transparent !important;
  border-radius: 5px 5px 0 0 !important;
  padding-bottom: 18px !important;
  border-bottom: 3px solid var.$primary !important;

  font-size: large !important;
  font-weight: bolder !important;

  line-height: 24px;
  color: var.$primary !important;
}

.react-tabs__tab:focus:after {
  content: none !important;
}
.react-tabs__tab {
  // color:rgba(0,0,0,.55) !important;
  font-size: large;
  font-weight: bolder;
}
.react-tabs__tab:active {
  color: var.$primary !important;
}
.react-tabs__tab:target {
  color: var.$primary !important;
}
.react-tabs__tab:visited {
  color: var.$primary !important;
}
.react-tabs__tab:focus-visible {
  color: var.$primary !important;
}
.webinar-card:hover {
  box-shadow: var.$shadow-primary;
}
.filter-webinar {
  .accordion-button {
    background-color: transparent !important;
    border-bottom: 1px solid var.$gray-800-dark !important  ;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom: transparent !important  ;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }
}
