.student-layout {
	margin-top: 40px !important;
}

.sidebar {
	top: 100px;
}

.scrolled {
	padding-top: 100px !important;
}

@media screen and (max-width: 576px) {
	.padding-layout {
		padding: 0 !important;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.sidebar {
		display: inline-block;
		position: sticky;
	}
	.padding-layout {
		margin-top: 80px;
		padding: 0 2rem !important;
	}
	.student-layout {
		margin-top: 70px !important;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.sidebar {
		display: inline-block;
		position: sticky;
	}
	.student-layout {
		margin-top: 70px !important;
	}
}

.elementMinHeight {
	min-height: 400px;	
}