.stories-wrapper {
	background-color: #f8eff3;
	background-image: url('../../../../public/media/background/stories-bg.png'); /* Add this line */
	background-size: cover;
	background-position: center;
	height: auto;
	object-fit: cover;
}

.stories-inner-layout {
	padding: 80px 0 80px 0;
}

// .containerHeight {
//   min-height: 600px;
// }

.stories-title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 600;
	color: #1a1a1a;
}

.stories-subtitle {
	font-size: 20px;
	line-height: 24px;
	color: #757575;
	// max-width: 500px;
	font-weight: 400;
	margin: auto;
}

.slider {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	margin: 50px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.prev {
	border: none;
	background: none;
}

.next {
	border: none;
	background: none;
}

.slick.active {
	position: relative;
	width: 542px;
	height: 360px;
	opacity: 1;
	z-index: 2;
	cursor: pointer;
}

.slide.prev {
	left: -10px;
}

.slide.next {
	right: -10px;
}

.slide-info {
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.slide.prev-enter,
.slide.next-enter {
	transform: scale(0.9);
	opacity: 0.8;
}

.slide.prev-enter-active,
.slide.next-enter-active {
	transform: scale(1);
	opacity: 1;
}

.slide.prev-leave-active,
.slide.next-leave-active {
	transform: scale(0.8);
	opacity: 0.5;
}

.radius {
	border-radius: 12px;
}

.video-play-button {
	position: absolute;
	z-index: 10;
	top: 46%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	box-sizing: content-box;
	display: block;
	width: 32px;
	height: 44px;
	border-radius: 50%;
	padding: 18px 20px 18px 28px;
	cursor: pointer;
	color: #A55BFF;
	background-color: #A55BFF;
}

.video-play-button:before {
	content: '';
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 55px;
	height: 55px;
	background: #A55BFF;
	border-radius: 50%;
	animation: pulse-border 1100ms ease-out infinite;
}

.video-play-button:after {
	content: '';
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 60px;
	height: 60px;
	background: #A55BFF;
	border-radius: 50%;
	transition: all 200ms;
}

.video-play-button span {
	display: block;
	position: relative;
	z-index: 3;
	width: 0;
	height: 0;
	border-left: 19px solid #fff;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	top: 10px;
	left: 5px;
	cursor: pointer !important;
}

@keyframes pulse-border {
	0% {
		transform: translateX(-50%) translateY(-50%) translateZ(1.5) scale(1.5);
		opacity: 1;
	}
	100% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
		opacity: 0;
	}
}
.slick-current {
	position: relative;
	z-index: 11;
}

@media (max-width: 1600px) {
	//.slick-slide:not(.slick-current) {
	//  visibility: hidden !important;
	//}

	.slick.active {
		video {
			margin: auto;
		}
	}
}

.studentStories {
	.slick-arrow {
		top: 48% !important;
		z-index: 2;
	}

	.slick-prev {
		left: 100px;
	}

	.slick-next {
		right: 100px;
	}

	.slick-arrow::before {
		content: '' !important;
	}

	.slick-slide {
		transition: all 0.5s ease;
		opacity: 0.5;
		transform: scale(0.8);
		margin-top: 10px;
	}

	.slick-center {
		opacity: 1 !important;
		transform: scale(1.1) !important;
	}

	.slide {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.descriptionField {
		position: absolute;
		bottom: 20px;
		left: 10px;
		color: white;
		font-size: 0.8rem;
		padding: 0 30px;
		width: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 3; /* Limits the text to 3 lines */
		text-overflow: ellipsis;

		@media (max-width: 992px) {
			left: 15vw;
			bottom: 5vh;
			width: 70%;
		}
		@media (max-width: 700px) {
			left: 5%;
			width: 90%;
		}
		@media (max-width: 576px) {
			bottom: 15px;
			padding: 0;
		}
	}
}

@media only screen and (max-width: 576px) {
	.stories-inner-layout {
		padding: 30px 0px;
	}
	.stories-title {
		font-size: 24px;
		line-height: 29px;
	}
	.stories-subtitle {
		font-size: 14px;
		line-height: 21px;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.stories-wrapper {
		margin: 0 16px 0;
		border-radius: 16px;
	}
	.video-play-button{
		padding: 0px 0px 0px 10px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.stories-wrapper {
		margin: 0 42px 0;
		border-radius: 16px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.stories-wrapper {
		margin: 0px 42px 0;
		border-radius: 16px;
	}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.stories-wrapper {
		margin: 0px 15rem 0;
		border-radius: 16px;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.stories-wrapper {
		margin: 0px 15rem 0;
		border-radius: 16px;
	}
}
