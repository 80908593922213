// All BreakPoints
:root {
  --breakpoint-small: ;
  --breakpoint-medium: ;
  --breakpoint-large: ;
  --breakpoint-xlarge: ;
  --breakpoint-xxlarge: ;
}

.list-courses-nav.list-courses-nav-tabs {
  border-bottom: 1px solid var(--color3);
}

.list-courses-nav .list-courses-nav-item .list-courses-nav-link {
  color: var(--color2);
  border-bottom: 2px solid var(--color2);
  background: transparent;
  border: none;
  font-weight: 600;
  transition: all 0s ease !important;
}

.list-courses-nav .list-courses-nav-item .list-courses-nav-link:hover,
.list-courses-nav .list-courses-nav-item .list-courses-nav-link.active {
  color: var(--color1) !important;
  border-bottom: 2px solid var(--color1) !important;
  font-weight: 600;
}

.list-courses-nav .list-courses-nav-item {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 0;
}

.list-courses-nav .list-courses-nav-item .list-courses-nav-link {
  width: 100%;
}

.course-card-hero-img {
  height: 200px;
  width: 100%;
  border-radius: 10px;
}

.card-mentor-name {
  line-height: 21px;
}

.course-price .price .amount span,
.course-price .price .course-free {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.course-price .price .amount i {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
}

.course-price .price .sell {
  color: #78828c;
  line-height: 21px;
}

.course-price .price .sell i {
  font-size: 12px;
  font-weight: 700;
}

.course-price .price .sell span {
  font-size: 16px;
  font-weight: 400;
}

.course-price .price .sell i {
  font-size: 12px;
  font-weight: 700;
}

.course-heading .title {
  color: #3c414b;
}

.filter .course-select-filter:focus {
  box-shadow: none;
}

.card-bookmark-icon {
  right: 10px;
  top: 10px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .course-card-hero-img {
    height: 225px !important;
  }

  .list-courses-nav .list-courses-nav-item {
    width: unset;
  }

  .list-courses-nav .list-courses-nav-item .list-courses-nav-link {
    width: unset;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .course-card-hero-img {
    height: 225px !important;
  }

  .list-courses-nav .list-courses-nav-item {
    width: unset;
  }

  .list-courses-nav .list-courses-nav-item .list-courses-nav-link {
    width: unset;
  }
}
