.star-detail {
  display: inline-block;
  position: relative;
  font-size: 1.25rem;
  color: lightgray; /* Unfilled star color */
}

.filled {
  color: var(--goldColor); /* Filled star color */
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  width: 0;
}
