.downloadapp-wraper {
	background-color: #f8eff3;
	background-image: url('../../../../public/media/background/download_bg.png'); /* Add this line */
	background-size: cover;
	background-position: center;
	height: auto;
	object-fit: cover;
	border: 1.5px solid #d8d8d8;
}

.content-spacing {
	padding: 120px 0;
}

.dapp-title {
	font-weight: 700;
	font-size: 4opx;
	line-height: 48px;
	color: #1a1a1a;
  text-align: left;
}

.dapp-subtitle {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
  text-align: left;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.downloadapp-layout {
		/* padding: 0px 30px 0px 20px !important; */
		/* background-color: #1a1a1a;
		border-radius: 16px;
		border: 4px solid #000000; */
	}

  .dapp-title {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

  .dapp-subtitle {
    font-size: 16px;
    line-height: 24px;
  }
  .content-spacing {
    padding: 40px 0;
    text-align: center;
  }

  .downloadapp-wraper {
    margin: 0 16px 0;
    border-radius: 16px;
  }
  .dapp-subtitle {
    text-align: center;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .content-spacing{
    text-align: center;
  }
  .dapp-title {
    font-size: 48px;
    line-height: 45px;
    text-align: center;
  }
  .download-btn{
    justify-content: center;
  }

  .downloadapp-wraper {
    margin: 0 42px 0;
    border-radius: 16px;
    /* border: 2px solid #000000; */
  }
  .dapp-subtitle {
    text-align: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .content-spacing{
    text-align: center;
  }
  .dapp-title {
    font-size: 38px;
    line-height: 45px;
    text-align: left;
  }
  .download-btn{
    justify-content: center;
    align-items: center;
  }
  .downloadapp-wraper {
    margin: 0 42px 0;
    border-radius: 16px;
  }
  .dapp-subtitle {
    text-align: left;
  }
}


/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .download-btn{
    justify-content: start;
  }
  .content-spacing{
    text-align: left;
  }
  .downloadapp-wraper {
    margin: 0 15rem 0;
    border-radius: 16px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .download-btn{
    justify-content: start;
  }
  .content-spacing{
    text-align: left;
  }
  .downloadapp-wraper {
    margin: 0 15rem 0;
    border-radius: 16px;
  }
}
