@use "./variable" as var;

.avatar.avatar-small {
  height: 60px;
  width: 60px;
}

.avatar.avatar-small-xs {
  height: 30px;
  width: 30px;
}

.avatar {
  height: 120px;
  width: 120px;
}
.avatar-lg {
  height: 50px;
  width: 50px;
}
