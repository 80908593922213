/* styles.css */

.course-tabs {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 16px 0;
  border: none;
  background-color: #FFFCF2;
}

.course-tabs.active {
  font-weight: 500;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 2px solid #000000 
}

#courseTabContent {
  padding: 20px;
}
.tab-list-course-tabs {
  position: relative;
  padding-bottom: 0px;
}
.tab-list-course-tabs li {
  display: inline-block;
  position: relative;
}

.tab-list-course-tabs li::after {
  width: 100%;
  position: absolute;
  bottom: 0; 
  left: 0;
}

.course-tabs.active {
  font-weight: 500;
  color: #1a1a1a;
}

.course-tabs.active::after {
  background-color: #000; 
}

.tab-list-course-tabs::before {
  position: absolute;
  width: 100%;
  height: 2px; 
  background-color: #d8d8d8; 
  bottom: 0;
  left: 0; 
  z-index: 0; 
}

.nav-tabs .nav-link.active {
  font-weight: bold;
  border: none !important; /* Adjust as needed */
  border-bottom: 2px solid black !important;
}

.tab-content {
  padding: 20px;
  border-top: 1px solid #dee2e6;
}

.courseTabs {
  padding: 0;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}


@media only screen and (max-width:750px) {
  .tab-list-course-tabs{
    width: 140% !important;
  }

}

@media only screen and (max-width:576px) {
  .tab-list-course-tabs{
    width: 170% !important;
  }
  .nav-items-course-tabs{
    margin-right: 15px !important;
  }
  .tab-list-course-tabs::before{
    bottom: 7px;
  }
}
@media only screen and (max-width:400px) {
  .tab-list-course-tabs{
    width: 190% !important;
  }
  .tab-list-course-tabs::before{
    bottom: 7px;
  }
}
