@use "../core/variable" as var;

.checkout {
  .accordion-button:not(.collapsed) {
    color: var.$black !important;
    font-weight: 600;
    box-shadow: none;
    background-color: none !important;
  }

  .accordion-button {
    display: inline-block;
  }
  .accordion-button:not(.collapsed)::after {
    display: none;
  }
  .accordion-button::after {
    background-image: none !important;
    display: none !important;
  }
  .checkout-number {
    color: var.$gray-500;
  }
  .accordion-button:not(.collapsed) {
    color: var.$black !important;
    box-shadow: none;
    background-color: transparent !important;
    border: 1px solid rgba(242, 244, 247, 1);
    font-weight: 600;
    border-bottom: none;
    .checkout-number {
      color: var.$primary;
    }
  }
  .accordion-item {
    border: none !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    background-color: var.$light;
    color: var.$gray-500;
  }
  .accordion-item:last-of-type .accordion-collapse {
    border: 1px solid rgba(242, 244, 247, 1);
    border-top: none;
  }
}
.payment-icon {
  font-size: 4rem;
  color: var.$success;
  // text-shadow: 0 0 3px #FF0000;;
}
