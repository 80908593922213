.hover-white {
    color: white !important;
    background-color: black !important;

    :hover {
        color: white !important;
    }
}

.hover-black {
    color: black !important;
   background-color: white !important;

    :hover {
        color: black !important;
    }
}