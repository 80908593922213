//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors

$white: #ffffff !default;
$black: #000000 !default;
$light-white: #f2f4f7 !default;
$light-gray: #b4bec8 !default;

// Primary
$primary: #3c78f0;
$primary-active: #002350;
$primary-light: #bedcff;
$primary-light-light: #3c78f01a;
$primary-light-dark: #3c414b;
$primary-inverse: #ffffff;

// Success
$success: #3cc878;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffa500;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;

// Gray
$gray-100-dark: #1b1b29 !default;
$gray-200-dark: #2b2b40 !default;
$gray-300-dark: #323248 !default;
$gray-400-dark: #474761 !default;
$gray-500-dark: #565674 !default;
$gray-600-dark: #6d6d80 !default;
$gray-700-dark: #92929f !default;
$gray-800-dark: #cdcdde !default;
$gray-900-dark: #ffffff !default;

// Bootstrap grey colors
$gray-100: #f9f9f9 !default;
$gray-200: #f4f4f4 !default;
$gray-300: #e1e3ea !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;

// Secondary colors
$secondary: $gray-300 !default;
$secondary-active: #6c757d !default;
$secondary-light: $gray-100 !default;
$secondary-inverse: $gray-800 !default;

// Light colors
$light: $gray-100 !default;
$light-active: $gray-200 !default;
$light-light: #ffffffbf !default;
$light-inverse: $gray-600 !default;

// Dark colors
$dark: $gray-900 !default;
$dark-active: darken($gray-900, 3%) !default;
$dark-light: $gray-200 !default;
$dark-inverse: $white !default;
$shadow-primary: 0px 0px 16px 0px rgba(60, 120, 240, 0.48) !default;

// Orange
$orange: #ff783c;
$orange-light: #f7961c33;

// blue
$blue: #002350;

// Custom class
.border-muted {
  border: 1px solid #f2f4f7;
}

.text-muted-dark {
  color: #78828c;
}

.text-muted-light {
  color: #78828c;
}

.bg-primary-50 {
  // background-color: #bedcff;
  background-color: #dbebff;
}

.bg-primary-30 {
  background-color: #dde8ff;
}
.text-hover-primary {
  color: $gray-500;
  transition: all 0.5s ease;
}
.text-hover-primary:hover {
  color: $primary;
}
.text-hover-success {
  color: $gray-500;
  transition: all 0.5s ease;
}
.text-hover-success:hover {
  color: $success;
}
.text-hover-primary-dark {
  color: #78828c;
  transition: all 0.5s ease;
}
.text-hover-primary-dark:hover {
  color: $primary;
}
