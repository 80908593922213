.best-wrapper {
	background-image: url('../../../../public/media/background/best-bg.png'); /* Add this line */
	background-position: center;
	height: auto;
	background-color: #1a1a1a;
}

.best-section-title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: #ffffff;
}

.number-style {
	font-size: var(--fs-48);
	line-height: 82px;
	color: #ffffff;
	font-weight: 700;
}

.number-desc {
	font-size: var(--fs-24);
	line-height: 28px;
	color: #ffffff;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.best-section-title {
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		color: #ffffff;
	}

	.number-style {
		font-size: 40px;
		line-height: 48px;
	}
	.best-wrapper {
		margin: 0 16px 0;
		border-radius: 16px;
	}
	.number-desc {
		font-size: var(--fs-16);
	}
}
/* Mobile */
@media (min-width: 480px) {
	.best-wrapper {
		margin: 0px 16px 0;
		border-radius: 16px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.best-wrapper {
		margin: 0px 16px 0;
		border-radius: 16px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.best-wrapper {
		margin: 0px 16px 0;
		border-radius: 16px;
	}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.best-wrapper {
		margin: 0px 15rem 0;
		border-radius: 16px;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.best-wrapper {
		margin: 0px 15rem 0;
		border-radius: 16px;
	}
}
