.optionBorder {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  width: 80%;
}

@media screen and (max-width: 576px) {
  .optionBorder {
    width: 100%
  }
}

.custom-radio .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: -5px;
  vertical-align: middle;
  background-color: transparent;
  border: 2px solid #757575;
  border-radius: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
}

.custom-radio .form-check-input:checked {
  background-color: #ffd700;
  border-color: #ffd700;
}

.custom-radio .form-check-input:checked::after {
  content: "";
  display: block;
  border-radius: 20%;
  background-color: #ffd700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-radio .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.custom-checkbox .form-check-input:checked {
  background-color: #ffd700;
  border-color: #ffd700;
  box-shadow: none;
}

.custom-checkbox .form-check-input:focus {
  box-shadow: none;
}

.custom-checkbox .form-check-input:checked::before {
  border-radius: 50%;
  background-color: #ffd700;
  border: 2px solid #ffd700;
}

.custom-checkbox .form-check-input:checked::after {
  background-color: #ffd700;
}

.timeText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.verticalSeparationLine {
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
  height: 100%;
  width: 1px;
}

.manageFullScreenPadding {
  padding: 3rem 3rem 6% !important;
}

.quiz-start-btn-style {
  background-color: #ffcd00;
  padding: 8px 24px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.quiz-question {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.quiz-option {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.quiz-option-layout {
  margin-left: -10px;
}

.quiz-timer-style {
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
}

.quiz-question-range {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.course-submit-text {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.course-yourscore-style {
  font-size: 18px;
  line-height: 27px;
  color: #1a1a1a;
}

.score-mark {
  font-size: 16px;
  line-height: 24px;
  color: #22211f;
}

.zoom-text {
  font-size: 52px;
  line-height: 78px;
  color: blue;
}

.workflow-text {
  font-size: 52px;
  line-height: 42px;
  color: #000000;
}

.join-meeting-btn {
  color: #ffffff;
  padding: 8px 12px !important;
  width: 90%;
  margin-left: 10px;
}

.signin-meeting-btn {
  border: 1px solid #d8d8d8;
  padding: 8px 12px !important;
  width: 90%;
  margin-left: 10px;
}

.signin-meeting-btn:hover {
  border: 1px solid #d8d8d8;
}
