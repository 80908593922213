.commonButtonClass {
    top: 40%;
    height: 64px;
    width: 34px;
    transition: all ease-in-out 0.2s;
}

.nextLessonButton {
    right: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.previousLessonButton {
    left: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}