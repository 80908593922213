/* .ac.active{
    background-color: red;
} */

.ac.active {
  color: red;
}
hr {
  color: rgb(190, 192, 196);
}
