@media screen and (min-width: 1200px) {
  .headerPadding {
    padding: 0 5rem !important;
    gap: 1rem;
  }
}

.verticalLine {
  height: 30px;
  background-color: #d8d8d8;
  width: 2px;
}

.progress-circle {
  position: relative;
  width: 50px;
  height: 50px;
}

.progress-circle svg {
  transform: rotate(-90deg);
}

.progress-circle circle {
  fill: none;
  stroke-width: 5;
}

.progress-circle .background-circle {
  stroke: #f0f0f0;
}

.progress-circle .progress-circle {
  stroke: var(--primaryColor);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s;
}

.progress-circle .progress-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.profile-mentor {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.profile-mentro-name {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.course-progress-style {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
  margin-bottom: 5px;
}
