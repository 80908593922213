@use "../core/variable" as var;

// h1,
// h2,
// h4,
// h3,
// h5,
// h6 {
//   font-family: "IBM Plex Serif";
// }
body {
  font-family: "Switzer";
}

// Section and container layout
.section {
  padding: 60px 0;
  position: relative;
}

// Heading
.sub-title-main {
  max-width: 450px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.title {
  font-family: "IBM Plex Serif";
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}

.sub-title {
  max-width: 850px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.heading-text {
  font-family: "IBM Plex Serif";
  font-weight: 600;
}

.mw-100 {
  max-width: 650px !important;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.border-line {
  border-top: 1px solid var.$light-white;
}

::placeholder {
  color: rgba(180, 190, 200, 1) !important;
}

.course-title-block {
  height: 50px;
}

.mentor-title-block {
  height: 30px;
}

.course_difficulty i {
  // border-radius: 4px;
  width: 25px;
  height: 25px;
}

.icon__course-difficulty.difficulty-Beginner span {
  // background-color: #0c0;
  background-color: #ff783c;
}

.icon__course-difficulty.difficulty-Intermediate span {
  // background-color: #ffc107;
  background-color: #ff783c;
}

.icon__course-difficulty.difficulty-Advanced span {
  // background-color: #ff0505;
  background-color: #ff783c;
}

.icon__course-difficulty .bar-1 {
  height: 33%;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
}

.icon__course-difficulty .bar-2 {
  height: 66%;
  animation-duration: 0.25s;
  animation-delay: 0.2s;
}

.icon__course-difficulty .bar-3 {
  height: 100%;
  animation-duration: 0.2s;
  animation-delay: 0.3s;
}

.icon__course-difficulty {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 4px;
}

.icon__course-difficulty span {
  display: inline-block;
  width: 6px;
  margin-left: 1px;
  transform-origin: 100% 100%;
  border-radius: 0px;
}

.border-orange {
  border-color: #ff783c !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.upcoming .card-hero-img,
.topSelling .card-hero-img {
  max-height: 50vh;
  height: 50vh;
  min-height: 30vh;
}

.mentor img {
  max-height: 75vh;
  height: 50vh;
  min-height: 30vh;
}

.testmonials .sub-title {
  max-width: 100% !important;
}

.nav-close-button {
  position: absolute;
  right: -20%;
  background-color: #fff;
  border: 0.5px solid #fff;
  border-radius: 50%;
}

.nav-close-button .btn-close {
  background: unset !important;
  color: #3c78f0;
}

.mobile-navbar-navs .nav-item.active .nav-link i,
.mobile-navbar-navs .nav-item.active .nav-link span {
  color: #3c78f0;
}

.hero-mentors-imgs:nth-child(2) {
  z-index: 2;
  margin-left: -10px;
}

.hero-mentors-imgs:nth-child(3) {
  z-index: 2;
  margin-left: -20px;
}

.checkout-accordion .accordion-button::after,
.checkout-accordion .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  transition: none !important;
  color: unset !important;
  content: unset !important;
}

.checkout-accordion .accordion-item,
.checkout-accordion .accordion-item:not(:first-of-type) {
  border: 1px solid #f2f4f7 !important;
}

.checkout-accordion .accordion-button:not(.collapsed) .checkout-number {
  color: #3c78f0 !important;
}

.checkout-accordion .accordion-button.collapsed .checkout-number {
  color: #a1a5b7 !important;
}

.course-new-faq .accordion-item,
.course-new-faq .accordion-item:not(:first-of-type) {
  border: 1px solid #f2f4f7 !important;
}

.course-new-faq .accordion-item .accordion-button.collapsed {
  background-color: #f2f4f7 !important;
}

.course-new-faq .accordion-item .accordion-button.collapsed,
.course-new-faq .accordion-item .accordion-button:not(.collapsed) {
  color: #3c414b !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.course-new-faq .accordion-item .accordion-body {
  color: #78828c !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.course-card {
  transition: all 0.7s ease;

  .image {
    .course-tag {
      right: 15px;
      position: absolute;
      top: 10px;
      transition: all 0.7s ease;
      opacity: 0;

      .tag {
        color: var.$gray-700;
        transition: all 0.5s ease;
      }
    }
  }

  :hover {
    .image {
      .course-tag {
        opacity: 1;

        :hover {
          .tag {
            color: var.$primary;
          }
        }
      }
    }
  }
}

h1,
.loaderText {
  font-family: Lato;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 1px;
}

.loader {
  text-align: center;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  height: 100px;
  border-top: 1.1em solid rgba(145, 145, 145, 0.2);
  border-right: 1.1em solid rgba(145, 145, 145, 0.2);
  border-bottom: 1.1em solid rgba(145, 145, 145, 0.2);
  border-left: 1.1em solid #3c78f0;
}

.loaderText {
  text-align: center;
  position: relative;
  top: -125px;
  text-shadow: 0px 0px 2px #3c78f0;
  opacity: 0.2;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cart-badge {
  position: absolute;
  top: -30px;
  right: -10px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }

  .title {
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
}

@media only screen and (min-width: 320px) {
  .container {
    max-width: 1000px;
  }

  .container-lg {
    max-width: 640px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 1000px;
  }

  .container-lg {
    max-width: 920px;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 1340px;
  }

  .container-lg {
    max-width: 1540px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1800px;
  }

  .container-lg {
    max-width: 1240px;
  }

  .upcoming .card-hero-img,
  .topSelling .card-hero-img {
    max-height: 60vh;
  }
}