.login-btn {
	font-size: 16px;
	cursor: pointer;
	width: 105px;
	padding: 10px 20px;
	text-align: center;
	border: 1px solid #ffffff;
	color: black;
	font-weight: 400;
	cursor: pointer;
	display: inline-block;
	background-color: transparent !important;
	border-radius: 12px;
	line-height: 27px;
	/* transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out; */
}
.login-btn-hover:hover {
	opacity: 80%;
}
.login-btn-hover-40:hover {
	opacity: 40%;
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

/* .login-btn-hover {
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
} */

/* .login-btn-hover:not(:hover) {
  animation: bounceBack 0.3s ease-in-out forwards;
} */

@keyframes bounceBack {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(5px);
	}
}
