.regi-title {
  font-size: 24px;
  line-height: 36px;
}

.label-style {
  font-size: 14px;
  line-height: 20px;
}

.btn.bg-color2:hover {
  background-color: #fecc00 !important;
}

.btn-registyle {
  margin-top: 30px !important;
  margin-bottom: 10px;
}

.disabled-btn {
  background-color: var(--secondaryColor) !important;
  cursor: not-allowed !important;
}

.modal-backdrop {
  background-color: #00000075 !important;
}

.cwg-regi-style {
  border: 1px solid #d8d8d8 !important;
  padding: 10px 24px !important;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 15px !important;
  margin-top: 10px;
}

.separator.separator-content::before,
.separator.separator-content::after {
  content: " ";
  width: 50%;
  /* border-bottom: 1px solid var(--bs-border-color); */
  border-bottom: 1px solid #888480 !important;
}

/* Style for disabled button */
.disabled-button {
  background-color: #ffe680 !important; /* Change background color */
  color: #999; /* Change text color */
  cursor: not-allowed; /* Change cursor */
  /* Add any additional styles as needed */
}

/* Style for disabled button on hover */
.disabled-button:hover {
  background-color: #ffe680 !important; /* Change background color */
  color: #999; /* Change text color */
}

.veri-otp-layout {
  margin-bottom: -0px;
}
