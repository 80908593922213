.faq{
	border-radius: 8px;
	
}
.faq .accordion-item{
	border-radius: 6px;
	overflow: hidden;
}

.faq-wrapper {
	border: 1px solid #d8d8d8;
	background-color: #fff;
	padding: 42px 0 42px 0;
}
.faq-title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: #1a1a1a;
}

.faq-subtitle {
	font-size: 18px;
	line-height: 27px;
	color: #757575;
	max-width: 550px;
	margin: auto;
}

.accordion-button {
	font-size: 18px !important;
	line-height: 30px !important;
	color: #1a1a1a !important;
	background-color: transparent !important;
}

.accordion-button:active {
	font-size: 18px !important;
	line-height: 30px !important;
	color: #1a1a1a !important;
}

.faq-answers {
	font-size: 16px !important;
	line-height: 30px !important;
	color: #1a1a1a !important;
	font-weight: 400 !important;
	
}
.faq-btn .accordion-button{
	padding: 16px 24px !important;
}
.faq-btn .accordion-button::after {
	background-image: url('../../../../public/media/logo/circle-down.png') !important;
	width: 24px !important;
	height: 24px !important;
	transform: scale(1) !important;
	transition: all 0.5s ease;

}

.faq-btn .accordion-button:not(.collapsed)::after {
	width: 24px !important;
	height: 24px !important;
	transform: scale(1) !important;
	transition: all 0.5s ease;
	background-image: url('../../../../public/media/logo/circle-up.png') !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
	.faq-title {
		font-size: 24px;
		line-height: 29px;
	}

	.faq-subtitle {
		font-size: 16px;
		line-height: 24px;
	}

	.accordion-button {
		border-radius: 12px !important;
		font-size: 14px !important;
		line-height: 21px !important;
	}

	.accordion-button:active {
		font-size: 14px !important;
		line-height: 21px !important;
		color: #1a1a1a !important;
	}

	.faq-answrs {
		font-size: 14px !important;
		line-height: 21px !important;
	}
	.faq-wrapper {
		margin: 80px 16px 80px;
		border-radius: 16px;
	}
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.faq-wrapper {
		margin: 80px 42px 80px;
		border-radius: 16px;
		/* border: 2px solid #000000; */
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.faq-wrapper {
		margin: 80px 42px 80px;
		border-radius: 16px;
	}
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.faq-wrapper {
		margin: 80px 15rem 80px;
		border-radius: 16px;
	}
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
	.faq-wrapper {
		margin: 80px 15rem 80px;
		border-radius: 16px;
	}
}
