@use "../core/variable" as var;

.workshop {
  // width: 100%;
  height: 270px;
  opacity: 0.6px;
  background: var.$gray-200;
}

// @media only screen and (min-width: 320px) and (max-width: 768px) {
//   .allWorkshop [class*="col-"] {
//     .img {
//       width: 100%;
//       height: 94.5px;
//       margin-left: 0px;
//     }
//   }
//   .recommend {
//     .card {
//       margin-top: 20px;
//     }
//   }
// }
