.header-layout-set {
	margin-top: 79px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
	.header-layout-set {
		margin-top: 71px;
	}
}

@font-face {
	font-family: 'p_reg';
	src: url('../../../../public/font/Poppins-Regular.ttf') format('woff');
}

.p_reg {
	font-family: 'p_reg';
}

@font-face {
	font-family: 'p_mid';
	src: url('../../../../public/font/Poppins-Medium.ttf') format('woff');
}

.p_mid {
	font-family: 'p_mid';
}

@font-face {
	font-family: 'p_bold';
	src: url('../../../../public/font/Poppins-Bold.ttf') format('woff');
}

.p_bold {
	font-family: 'p_bold';
}

@font-face {
	font-family: 'p_thin';
	src: url('../../../../public/font/Poppins-Thin.ttf') format('woff');
}

.p_thin {
	font-family: 'p_thin';
}

@font-face {
	font-family: 'p_semi';
	src: url('../../../../public/font/Poppins-SemiBold.ttf') format('woff');
}

@font-face {
	font-family: 'g_black';
	src: url('../../../../public/font/Graphik-Black.ttf') format('woff');
}

.g_black {
	font-family: 'g_black';
}

@font-face {
	font-family: 'g_bold';
	src: url('../../../../public/font/Graphik-Bold.ttf') format('woff');
}

.g_bold {
	font-family: 'g_bold';
}

@font-face {
	font-family: 'g_extralight';
	src: url('../../../../public/font/Graphik-Extralight.ttf') format('woff');
}

.g_extralight {
	font-family: 'g_extralight';
}

@font-face {
	font-family: 'g_light';
	src: url('../../../../public/font/Graphik-Light.ttf') format('woff');
}

.g_light {
	font-family: 'g_light';
}

@font-face {
	font-family: 'g_mid';
	src: url('../../../../public/font/Graphik-Medium.ttf') format('woff');
}

.g_mid {
	font-family: 'g_mid';
}

@font-face {
	font-family: 'g_reg';
	src: url('../../../../public/font/Graphik-Regular.ttf') format('woff');
}

.g_reg {
	font-family: 'g_reg';
}

@font-face {
	font-family: 'g_semi';
	src: url('../../../../public/font/Graphik-Semibold.ttf') format('woff');
}

.g_semi {
	font-family: 'g_semi';
}

@font-face {
	font-family: 'g_super';
	src: url('../../../../public/font/Graphik-Super.ttf') format('woff');
}

.g_super {
	font-family: 'g_super';
}

@font-face {
	font-family: 'g_thin';
	src: url('../../../../public/font/Graphik-Thin.ttf') format('woff');
}

.g_thin {
	font-family: 'g_thin';
}

@font-face {
	font-family: 'lora';
	src: url('../../../../public/font/Lora-VariableFont_wght.ttf') format('woff');
}

.lora {
	font-family: 'lora';
}
@font-face {
	font-family: 'ibm_reg';
	font-weight: 400;
	font-style: normal;
	src: url('../../../fonts/IBMPlexSerif-Regular.ttf') format('woff');
}

.ibm_reg {
	font-family: 'ibm_reg';
}

@font-face {
	font-family: 'ibm_med';
	font-weight: 500;
	font-style: normal;
	src: url('../../../fonts/IBMPlexSerif-Medium.ttf') format('woff');
}

.ibm_med {
	font-family: 'ibm_med';
}

@font-face {
	font-family: 'ibm_semi';
	font-weight: 600;
	font-style: normal;
	src: url('../../../fonts/IBMPlexSerif-SemiBold.ttf') format('woff');
}

.ibm_semi {
	font-family: 'ibm_semi';
}

@font-face {
	font-family: 'ibm_sup';
	font-weight: 700;
	font-style: normal;
	src: url('../../../fonts/IBMPlexSerif-Bold.ttf') format('woff');
}
.ibm_sup{
	font-family: 'ibm_sup';
}

@font-face {
	font-family: 'britania';
	src: url('../../../../public/font/Britania_Ligatura.ttf') format('woff');
}

.britania {
	font-family: 'britania';
}

.border-style {
	border: 1px solid #d5d1cc !important;
}

.border-style-black {
	border: 1px solid black;
}

.image-fit-contain {
	object-fit: contain;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@100;200;300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --defualt-white: #ffffff;
  --defualt-black: #000000;
  --transparent: transparent;
  --primaryColor: #A55BFF;
  --secondaryColor: #F8EFF3;
  --backgroundGlobal: #fffcf2;
  --goldColor: #F69C08;
  --black: #000000;
  --white: #ffffff;
  --color1: #888480;
  --color2: #ffcd00;
  --color3: #22211f;
  --color4: #1f2224;
  --color5: #f2f0ef;
  --color6: #413f3d;
  --color7: rgba(231, 242, 255, 1);
  --color8: rgba(254, 204, 0, 1);
  --color9: #16a34a;
  --color10: #f03c3c;
  --color11: #63615e;
  --color12: #f59e0b;

  --color13: #ffe680;
  --color14: #3c78f0;
  --color15: #ffcd00;
  --color16: #1a1a1a;
  --color17: #757575;
  --color18: #ececec;
  --color19: #fffcf2;
  --color20: #ededed;
  --color21: #D8D8D8;
  --color22: #3C414B;
  --color23: #161C2D;
  --color24: #F7F1FFCC;
  --width10: 0.1rem;
  --width20: 0.2rem;
  --width30: 0.3rem;
  --width40: 0.4rem;
  --width60: 0.6rem;
  --width70: 0.7rem;
  --width80: 0.8rem;
  --width90: 0.9rem;
  --widthp10: 10%;
  --widthp20: 20%;
  --widthp30: 30%;
  --widthp40: 40%;
  --widthp60: 60%;
  --widthp70: 70%;
  --widthp80: 80%;
  --widthp90: 90%;
  --widthv10: 10vw;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-26: 1.625rem;
  --fs-28: 1.75rem;
  --fs-30: 1.875rem;
  --fs-32: 2rem;
  --fs-34: 2.125rem;
  --fs-36: 2.25rem;
  --fs-38: 2.375rem;
  --fs-40: 2.5rem;
  --fs-44: 2.75rem;
  --fs-48: 3rem;
  --fs-52: 3.25rem;
  --fs-56: 3.5rem;
  --fs-72: 4.5rem;
}

.outline-none {
	outline: none !important;
}

.box-shadow-none {
	box-shadow: none !important;
}

/* font-weigth classes */
fw-exlight {
	font-weight: 100;
}

fw-exbold {
	font-weight: 800;
}

fw-drkbold {
	font-weight: 900;
}

.layout-set {
	margin: auto;
}

/* color classes */
.black {
	color: var(--black);
}

.goldColor {
	color: var(--goldColor);
}

.white {
	color: var(--white);
}

.primaryColor {
	color: var(--primaryColor);
}

.secondaryColor {
	color: var(--secondaryColor);
}

.color1 {
	color: var(--color1);
}

.color2 {
	color: var(--color2);
}

.color3 {
	color: var(--color3);
}

.color4 {
	color: var(--color4);
}

.color5 {
	color: var(--color5);
}

.color6 {
	color: var(--color6);
}

.color7 {
	color: var(--color7);
}

.color8 {
	color: var(--color8);
}

.color9 {
	color: var(--color9);
}

.color10 {
	color: var(--color10);
}

.color11 {
	color: var(--color11);
}

.color12 {
	color: var(--color12);
}

.color13 {
	color: var(--color13);
}

.color14 {
	color: var(--color14);
}

.color15 {
	color: var(--color15);
}

.color16 {
	color: var(--color16);
}

.color17 {
	color: var(--color17);
}

.color18 {
	color: var(--color18);
}

.color20 {
	color: var(--color20);
}

.color21 {
	color: var(--color21);
}

.color22 {
	color: var(--color22);
}

.color23 {
	color: var(--color23);
}

.bg-none {
	background: none;
}

/* BgColor classes */

.bg-white {
	background-color: var(--white);
}

.bg-black {
	background-color: var(--black);
}

.bg-primaryColor {
	background-color: var(--primaryColor) !important;
}

.bg-secondaryColor {
	background-color: var(--secondaryColor);
}

.bg-backgroundGlobal {
	background-color: var(--backgroundGlobal);
}

.bg-color1 {
	background-color: var(--color1);
}

.bg-color2 {
	background-color: var(--color2);
}

.bg-color3 {
	background-color: var(--color3);
}

.bg-color4 {
	background-color: var(--color4);
}

.bg-color5 {
	background-color: var(--color5);
}

.bg-color6 {
	background-color: var(--color6);
}

.bg-color7 {
	background-color: var(--color7);
}

.bg-color8 {
	background-color: var(--color8);
}

.bg-color9 {
	background-color: var(--color9);
}

.bg-color10 {
	background-color: var(--color10);
}

.bg-color11 {
	background-color: var(--color11);
}

.bg-color13 {
	background-color: var(--color13);
}

.bg-color11:hover {
	background-color: var(--color11);
	color: black;
}

.bg-color15 {
	background-color: var(--color15);
}
.bg-color16 {
	background-color: var(--color16);
}
.bg-color18 {
	background-color: var(--color18);
}

.bg-color12 {
	background-color: var(--color12);
}

.bg-color19 {
	background-color: var(--color19);
}

.bg-color23 {
	background-color: var(--color23);
}

.bg-color24 {
	background-color: var(--color24);
}

/* border classes */

.border-primaryColor {
	border: 1px solid var(--primaryColor);
}
.border-grey-color {
	border: 1px solid var(--color17);
}

.border-light-background {
	border: 1px solid var(--color21);
}
.border-color1 {
	border-color: var(--color1);
}

.border-color2 {
	border-color: var(--color2);
}

.border-color3 {
	border-color: var(--color3);
}

.border-color4 {
	border-color: var(--color4);
}

.border-color5 {
	border-color: var(--color5);
}

.border-color6 {
	border-color: var(--color6);
}

.border-color8 {
	border-color: var(--color8);
}

.border-color11 {
	border-color: var(--color11);
}

.border-color14 {
	border-color: var(--color14);
}

.border-color16 {
	border: 1px solid var(--color16)
}

.border-color21 {
	border: 1px solid var(--color21)
}

.btn-common-slider {
	background: var(--secondaryColor);
	border: 1px solid var(--primaryColor);
	font-family: 'g_mid';
	font-size: var(--fs-18);
	border-radius: var(--fs-12);
	padding: var(--fs-12) var(--fs-24);
}

.py-6 {
	padding-top: 4.5rem !important;
	padding-bottom: 4.5rem !important;
}

.py-7 {
	padding-top: 6rem !important;
	padding-bottom: 6rem !important;
}

.py-8 {
	padding-top: 7.5rem !important;
	padding-bottom: 7.5rem !important;
}

.py-9 {
	padding-top: 9rem !important;
	padding-bottom: 9rem !important;
}

.py-10 {
	padding-top: 10.5rem !important;
	padding-bottom: 10.5rem !important;
}

.my-6 {
	margin-top: 4.5rem !important;
	margin-bottom: 4.5rem !important;
}

.my-7 {
	margin-top: 6rem !important;
	margin-bottom: 6rem !important;
}

.my-8 {
	margin-top: 7.5rem !important;
	margin-bottom: 7.5rem !important;
}

.my-9 {
	margin-top: 9rem !important;
	margin-bottom: 9rem !important;
}

.my-10 {
	margin-top: 10.5rem !important;
	margin-bottom: 10.5rem !important;
}

/* main classes */
.text-justify {
	text-align: justify;
}

/* width classes */

.w-10 {
	width: var(--width10);
}

.w-20 {
	width: var(--width20);
}

.w-30 {
	width: var(--width30);
}

.w-40 {
	width: var(--width40);
}

.w-60 {
	width: var(--width60);
}

.w-70 {
	width: var(--width70);
}

.w-80 {
	width: var(--width80);
}

.w-90 {
	width: var(--width90);
}

.w-vw-10 {
	width: var(--widthv10);
}

.fs-12 {
	font-size: var(--fs-12);
}
.fs-14 {
	font-size: var(--fs-14);
}

.fs-16 {
	font-size: var(--fs-16);
}

.fs-18 {
	font-size: var(--fs-18);
}
.fs-20 {
	font-size: var(--fs-20);
}
.fs-24 {
	font-size: var(--fs-24);
}
.fs-28 {
	font-size: var(--fs-28);
}

.fs-32 {
	font-size: var(--fs-32);
}

.fs-40 {
	font-size: var(--fs-40);
}

.fs-72 {
	font-size: var(--fs-72);
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.header-nav-link {
		font-size: var(--fs-14);
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.w-md-10 {
		width: var(--width10);
	}

	.w-md-20 {
		width: var(--width20);
	}

	.w-md-30 {
		width: var(--width30);
	}

	.w-md-40 {
		width: var(--width40);
	}

	.w-md-60 {
		width: var(--width60);
	}

	.w-md-70 {
		width: var(--width70);
	}

	.w-md-80 {
		width: var(--width80);
	}

	.w-md-90 {
		width: var(--width90);
	}

	.w-md-p-10 {
		width: var(--widthp10);
	}

	.w-md-p-20 {
		width: var(--widthp20);
	}

	.w-md-p-30 {
		width: var(--widthp30);
	}

	.w-md-p-40 {
		width: var(--widthp40);
	}

	.w-md-p-60 {
		width: var(--widthp60);
	}

	.w-md-p-70 {
		width: var(--widthp70);
	}

	.w-md-p-80 {
		width: var(--widthp80);
	}

	.w-md-p-90 {
		width: var(--widthp90);
	}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.w-lg-10 {
		width: var(--width10);
	}

	.w-lg-20 {
		width: var(--width20);
	}

	.w-lg-30 {
		width: var(--width30);
	}

	.w-lg-40 {
		width: var(--width40);
	}

	.w-lg-60 {
		width: var(--width60);
	}

	.w-lg-70 {
		width: var(--width70);
	}

	.w-lg-80 {
		width: var(--width80);
	}

	.w-lg-90 {
		width: var(--width90);
	}
}
