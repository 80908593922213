@use "../core/variable" as var;

.slick-dots li button {
  padding: 0 !important;
}
.slick-dots li button:before {
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.75;
  color: var.$gray-700;
}
.slick-dots li {
  padding: 0 !important;
  margin: 0 !important;
}
.slick-arrow.-next,
.slick-arrow.-prev {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  background: 0 0;
  border: 0;
  transition: all 0.15s ease;
}

.slick-disabled {
  color: var.$primary !important;
  // background-color: var.$light;
  background-color: var.$dark;
  opacity: 1;
}
.slick-dots li.slick-active button:before {
  color: var.$primary;
}

.-arrow.-next:after,
.-arrow.-prev:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var.$primary;
  background-color: var.$light;
  border: 1px solid var.$primary;
  transition: all 0.5s ease;
  z-index: 1;
  box-shadow: 0 25px 25px rgba(36, 39, 44, 0.05);
}
.-arrow.-next:before,
.-arrow.-prev:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var.$primary;
  background-color: var.$light;
  border: 1px solid var.$primary;
  box-shadow: 0 0 3px rgba(36, 39, 44, 0.15);
  transition: all 0.5s ease;
  z-index: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: inline-flex;
}
.-arrow.-next:hover:before,
.-arrow.-prev:hover:before {
  color: var.$light;
  background-color: var.$primary;
  border: 1px solid var.$light;
}
.-next:before {
  content: ">";
  padding-bottom: 3px;
}
// .-next:focus:before,
// .-next:hover:before,
// .-prev:focus:before,
// .-prev:hover:before {
//   color: var.$light;
// }
.-next.-disabled:before,
.-prev.-disabled:before {
  opacity: 1;
}
.-prev {
  left: -10px !important;
  z-index: 1;
}
.-next {
  right: 22px !important;
}
.-prev:before {
  content: "<";
  padding-bottom: 3px;
}
.-slider {
  overflow-x: hidden;
  // overflow-y: hidden;
}

.fa-star:before {
  color: var.$warning;
}

body {
  .-slider {
    overflow-x: hidden;
  }
}

// .slick-track {
//   display: flex;

//   .slick-slide {
//     display: flex;
//     height: auto !important;

//     img {
//       height: 100%;
//       object-fit: cover;
//       object-position: center;
//     }
//   }
// }
