.change-password-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #1a1a1a;
}

.change-labels {
  font-size: 14px;
  line-height: 21px;
  color: #757575;
  margin-bottom: 8px;
}

.rules-layout {
  border-radius: 8px;
  background-color: #f1f1f1;
  padding: 24px;
}

.rules-title {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.rules-label {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
}

.rules-label > li {
  margin-bottom: 10px;
}

.change-otp-btn {
  font-size: 16px !important;
  line-height: 24px;
  color: #1a1a1a;
  background-color: #ffcd00;
  padding: 8px 26px !important;
  border-radius: 12px !important;
}
.change-otp-btn:hover {
  background-color: #ffcd00;
}
.btn.eye-icon-outline:active {
  border-color: white;
}
