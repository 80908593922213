@use "../core/variable" as var;

.btn {
  padding: 10px 20px;
  outline: none;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 500;
  border-radius: 5px;
}

.btn {
  display: inline-block;
  align-self: center;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-outline-success {
  color: var.$success;
  border-color: var.$success;
}

.btn-outline-orange {
  color: var.$orange;
  border-color: var.$orange;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: var.$primary;
  background-color: var.$white;
  border-color: var.$primary;
  box-shadow: var.$shadow-primary;
}

.btn-primary-50 {
  color: var.$dark;
  background-color: var.$primary-light-light;
}

.btn-primary:hover,
.btn-primary:active {
  color: var.$white;
  background-color: var.$primary;
  border-color: var.$primary;
  box-shadow: var.$shadow-primary;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: var.$dark;
  background-color: var.$white;
  border-color: var.$secondary;
  // box-shadow: var.$shadow-secondary;
}

.eye-icon {
  color: var.$dark;
  right: 1%;
  z-index: 9 !important;
}

.filter-value {
  color: var.$dark;
  background-color: var.$white;
  border-color: var.$secondary;
  // box-shadow: var.$shadow-secondary;
}
