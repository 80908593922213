.container {
  max-width: 1200px;
}
.notification-table.notification-table-data {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}
.notification-table.notification-table-data th,
.notification-table.notification-table-data td {
  padding: 1rem;
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid rgb(244, 244, 244);
}
.notification-table.notification-table-data thead th {
  border-bottom: 2px solid rgb(244, 244, 244);
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
}
.notification-table.notification-table-data tbody tr:last-child td {
  border-bottom: none;
}
.notification-table .notification-table-data tbody tr:hover {
  background-color: #f1f3f5;
}
/* .notification-table-data img {
  width: 24px;
  height: 24px;
} */
.no-notifications-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
}
.no-notifications-desc {
  font-size: 1rem;
  color: #757575;
}
.noti-table-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #1a1a1a;
}
.text-center {
  text-align: center;
}
.notification-table > .notification-table-data > thead > tr > th,
.notification-table > .notification-table-data > tbody > tr > td {
  padding: 1.5rem 0.5rem;
}
/* @media (max-width: 768px) {
  .notification-table-data thead {
    display: none;
  }
  .notification-table-data tbody tr {
    display: block;
    margin-bottom: 1rem;
  }
  .notification-table-data tbody td {
    display: block;
    text-align: right;
    font-size: 0.875rem;
    border-bottom: 1px solid rgb(244, 244, 244);
  }
  .notification-table-data tbody td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
  .notification-table-data tbody td:last-child {
    border-bottom: 0;
  }
} */
