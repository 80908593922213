.payment-detail-wrapper {
	margin: 40px 0;
	background-image: url('../../../../public/media/background/howwork-bg.png'); /* Add this line */
	background-size: cover;
	background-position: center;
	height: auto;
	object-fit: cover;
}

.arrow-layout {
	margin-left: 20px;
	margin-right: 90px;
	cursor: pointer;
}

.card-layout {
	width: 520px;
	margin-top: 40px;
}

.payment-course-name {
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.payment-course-label {
	font-size: 12px;
	line-height: 18px;
	color: #757575;
	margin-top: 5px;
}

.summary-heading {
	font-size: 18px;
	line-height: 27px;
	color: #757575;
	margin-top: 25px;
}

.payment-discount-price {
	font-size: 22px;
	line-height: 27px;
	color: #1a1a1a;
}

.payment-actual-price {
	font-size: 18px;
	line-height: 27px;
	color: #757575;
}

.payment-border {
	border: 1px solid #d8d8d8;
	border-style: dashed;
	margin-top: 15px;
	margin-bottom: 15px;
}

.amount-label {
	font-size: 16px;
	line-height: 24px;
	color: #757575;
	margin-bottom: 12px;
}

.subtotal-label {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
	margin-bottom: 12px;
	font-weight: bold;
}

.total-price-label {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	margin-top: 5px;
	font-weight: bold;
}

.payment-proceed-btn {
	margin-bottom: 10px;
	font-size: 18px;
	cursor: pointer;
	width: 18%;
	padding: 12px 20px;
	text-align: center;
	color: #1a1a1a !important;
	display: inline-block;
	background-color: #ffcd00 !important;
	border-radius: 12px;
	line-height: 27px;
	transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
	/* 
  &:hover {
    background-color: #ffcd00 !important;
    box-shadow: 1px 8px #000000;
    color: #1a1a1a !important;
    animation: bounce 0.3s ease-in-out forwards;
  } */
	/* 
  &.payment-proceed-btn-hover:hover {
    background-color: #ffcd00 !important;
    box-shadow: 1px 8px #000000;
    color: #1a1a1a !important;
    animation: bounce 0.3s ease-in-out forwards;
  } */

	/* &.payment-proceed-btn-hover {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  } */
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

@keyframes bounceBack {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(5px);
	}
}

.payment-sub-desc {
	font-size: 11px;
	line-height: 17px;
	color: #757575;
}
@media only screen and (max-width: 576px) {
	.card-layout {
		width: 450px;
		margin-top: 10px;
	}
}

.cardMinHeight {
	min-height: 450px;
}