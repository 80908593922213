@use "../core/variable" as var;

.cartSlider {
  border-bottom: 2px solid var.$gray-200;
}

.qty-input-box {
  width: 50px;
  height: 40px;
  text-align: center;
  color: var.$black;
  display: inline-block;
  border: none;
  transition: 0.3s;
}
.border {
  border: 2px solid var.$gray-500;
}
.prdct-qty-btn {
  width: 30px;
  height: 40px;
  border: none;
  transition: 0.3s;
}
.qty-input-box:focus {
  outline: 0;
}

.prdct-qty-btn:hover {
  background-color: var.$primary;
  color: var.$white;
}

.product-img img {
  width: 40px;
  height: 40px;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .cart {
    .offcanvas {
      max-width: 321px;
    }
  }
}
