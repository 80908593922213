.cls-MdFilterList {
  color: blue;
}

.orange-font {
  color: #ff783c;
}

.bookmark-icon-look,
.cart-icon-look {
  background-color: #f2f4f7;
  width: 100%;
  text-align: center;
}
