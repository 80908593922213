.about-content-card-title{
    font-size: 22px;
    line-height: 33px;
    color: var(--black);
    font-family: "g_mid";
}

.about-content-card-subtitle{
    font-size: 15px;
    line-height: 325x;
    color: var(--color17);
    font-family: "g_reg";
}

.about-content-card-btn{
    background-color: var(--primaryColor);
    color: #FFFFFF;
    font-size: 15px;
    line-height: 23px;
    font-family: "g_mid";
    border: 1px solid #FECC00;
}

.about-content-card-btn:hover {
    background-color: var(--primaryColor); 
    color: var(--white); 
    border: 1px solid #FECC00;
}

.image-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.about-approach-card-title{
    font-size: 36px;
    line-height: 40px;
    color: #0B0D0E;
    font-family: "g_mid";
}

.about-approach-card-subtitle{
    font-size: 18px;
    line-height: 27px;
    font-family: "g_reg";
    color: #5C5F6E;
}

.expect-title{
    font-size: 32px;
    line-height: 48px;
    font-family: "g_mid";
    color: #0B0D0E;
}

.expect-subtitle{
    font-size: 18px;
    line-height: 27px;
    font-family: "g_reg";
    color: #838696;
}

.about-cta-title{
    font-size: 32px;
    line-height: 36px;
    color: var(--white);
    font-family: "g_mid";
}

.about-cta-subtitle{
    font-size: 16px;
    line-height: 24px;
    font-family: "g_reg";
    color : #F1F1F1
}

.query-course-btn{
    background-color: var(--color2);
    color: var(--color16);
    font-size: 18px;
    line-height: 27px;
    font-family: "g_mid";
    border: 1px solid #1a1a1a;
    border-radius: 64px;
    padding: 10px 15px !important;
}

.query-course-btn:hover{
    background-color: var(--color2);
    color: var(--color16);
    border: 1px solid #1a1a1a;
}

.query-contact-btn{
    background-color: var(--white);
    color: var(--color16);
    font-size: 18px;
    line-height: 27px;
    font-family: "g_mid";
    border: 1px solid #1a1a1a;
    border-radius: 64px;
    padding: 10px 15px !important;
}

.query-contact-btn:hover{
    background-color: var(--white);
    color: var(--color16);
    border: 1px solid #1a1a1a;
}
.query-card-title{
    font-size: 28px;
    line-height: 30px;
    font-family: "g_mid";
    color: var(--black);
}

.query-card-subtitle{
    font-size: 16px;
    line-height: 24px;
    font-family: "g_reg";
    color: #838696;
}

.custom-card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}




