.signup-btn {
  font-size: 16px;
  cursor: pointer;
  width: 105px;
  padding: 10px 20px;
  text-align: center;
  color: #000000 !important;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  background-color: #ffcd00;
  border-radius: 12px;
  line-height: 27px;
  /* transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out; */
}

.signup-btn-hover:hover {
  opacity: 80%;
}

/* .signup-btn:hover {
  border: 1px solid #1a1a1a !important;
} */

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* .signup-btn-hover {
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

.signup-btn-hover:not(:hover) {
  animation: bounceBack 0.3s ease-in-out forwards;
} */

@keyframes bounceBack {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}