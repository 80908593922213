.transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.transition-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition:
    opacity 300ms,
    transform 300ms;
}

.lessonHeading {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #22211f;
}

.lectureButtons {
  position: absolute;
  bottom: 0;
}

.dashedLine {
  border-top: 2px dashed #757575;
}

.completedHeading {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
}

.courseContainer {
  min-height: 670px;
  border: 1px #757575 solid;
  border-radius: 1%;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .courseContainer {
    min-height: 300px;
    border: none;
  }
}

.btn-next-lesson {
  line-height: 18px;
  border-radius: 12px !important;
  padding: 10px 22px !important;
}

.btn-previous {
  color: #757575 !important;
  border-radius: 12px !important;
  border-color: #d8d8d8 !important;
  padding: 10px 22px !important;
}

@media screen  and (max-width: 576px){
  .btn-previous{
    padding: 10px !important
  }
  .btn-next-lesson{
    padding: 10px !important;
  }
}