@use "../core/variable" as var;

.footer {
  background: var.$blue;
  position: relative;
  color: var.$light-gray;

  .footer-py-60 {
    padding: 50px 0;
  }

  .logo-footer {
    font-size: 22px;
  }

  .social-icon li a {
    color: var.$light;
    border: 1px solid var.$gray-800;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    text-decoration: none;
  }

  .footer-head {
    letter-spacing: 1px;
    font-weight: 500;
    color: var.$white;
  }

  .footer-list li a {
    text-decoration: none;
    transition: all 0.5s ease;
  }

  .text-foot {
    color: var.$gray-500;
    font-size: 0.9rem;
  }

  .text-foot:hover {
    color: var.$white;
  }

  .footer-text {
    max-width: 280px;
  }

  .footer-border,
  .footer-bar {
    border-top: 1px solid var.$gray-700;
  }
}

@media (max-width: 767.98px) {
  .lastsection {
    margin-left: -38px;
  }

  .list-inline-item {
    margin-left: -5px;
  }

  .Footer_last {
    margin-left: 20px;
  }
}

// @media (min-width: 901px) and (max-width:1400px){
//   .lastsection{
//     padding-left : 3rem !important
//   }
// }

// .carousel {
//   position: relative;
// }
// .carousel-control-prev , .carousel-control-next {
//   position: absolute;
//   top: 50%;
//   transform: TranslateY(-50%);
// }
// .carousel-control-prev {
//   right: auto;
//   left: 20px;
// }
// .carousel-control-next {
//   left: auto;
//   right: 20px;
// }
