.button_home_tag {
  font-size: 12px !important;
  line-height: 18px;
  color: #757575;
  padding: 4px 12px !important;
  margin-top: 15px;
  margin-bottom: 10px;
}

.uper-layout {
  margin-top: -5px !important;
}

.review_number {
  font-size: 12px;
  line-height: 18px;
  color: #1a1a1a;
  font-weight: 400;
}

.card_text {
  font-size: 14px !important;
  line-height: 21px;
  color: #000000;
  font-weight: 600;
}

.card_back_text {
  font-size: 16px;
  line-height: 24px;
  color: #241c15;
  font-weight: 600 !important;
}

.card_back_desc {
  font-size: 14px;
  line-height: 21px;
  color: #241c15;
}

.btn_enroll_now {
  background-color: #ffcd00;
  font-size: 14px !important;
  line-height: 16px;
  color: #241c15;
  border-radius: 12px !important;
  padding: 10px 22px !important;
}

.btn_enroll_now:hover {
  background-color: #ffcd00;
}

.btn_view_details {
  font-size: 14px !important;
  line-height: 16px;
  color: #1a1a1a;
  border-radius: 12px !important;
  padding: 10px 22px !important;
}

.homecard {
  border: 1px solid #f1f1f1 !important;
}

.final_price {
  font-size: 24px;
  line-height: 36px;
  color: #241c15;
}

.actual_price {
  font-size: 14px;
  line-height: 21px;
  color: #241c15;
}

.nodata-title {
  font-size: 24px;
  line-height: 29px;
  color: #22211f;
  font-weight: 700;
  margin-top: 30px;
}

.nodata-label {
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  max-width: 350px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 30px;
}

.btn_goto_course {
  background-color: #ffcd00;
  font-size: 16px !important;
  line-height: 24px;
  color: #241c15;
  border-radius: 12px !important;
  padding: 12px 24px !important;
  border: 1px solid #000000;
}

.btn_goto_course:hover {
  background-color: #ffcd00;
  border: 1px solid #000000;
}
