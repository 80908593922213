@use "../core/variable" as var;

.accordion-button:not(.collapsed) {
  color: var.$black !important;
  font-weight: 400;
  box-shadow: none;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
  background-color: none !important;
}
.accordion {
  flex: 1 1 auto;
  min-height: 1px;
}
.accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-item:not(:first-of-type) {
  border: 1px solid var.$gray-100;
}

.faq {
  .accordion-item {
    border: 1px solid black !important;
  }
  .accordion-item:last-of-type .accordion-collapse {
    border: 1px solid var.$light-white;
    border-top: none;
  }
  // .accordion-button {
  //   background-color: var.$light !important;
  // }
  .accordion {
    padding: 9px !important;
  }
}
.accordion-button:not(.collapsed)::after {
  transition: all 0.5s ease;
  background-image: url('../../../../public/media/logo/circle-up.png') !important;
  color: var.$gray-300;
  transform:none;
}
.accordion-button::after {
  background-image: url('../../../../public/media/logo/circle-down.png') !important;
  transition: all 0.5s ease;
  color: var.$gray-300;
  transform:none;
}
