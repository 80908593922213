.uk .uk-grid-small .uk-countdown-label {
  color: var(--color5);
  font-family: var(--secondary-font);
  font-size: var(--fs-16);
}

.uk .uk-grid-small .uk-countdown-separator {
  font-family: var(--primary-font);
}

.uk .uk-grid-small .uk-countdown-number {
  color: var(--color9);
  font-weight: 700;
  font-family: var(--primary-font);
  font-size: var(--fs-30);
  background-color: var(--color10);
  height: 5rem;
  width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.live-dot {
  background-color: #ff0000;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 0px #ff0000;
  }

  25% {
    box-shadow: 0 0 10px #f03c3c;
  }

  50% {
    box-shadow: 0 0 20px #f03c3c;
  }

  75% {
    box-shadow: 0 0 10px #f03c3c;
  }

  100% {
    box-shadow: 0 0 0px #ff0000;
  }
}

.glow {
  animation: glowing 1250ms infinite;
  animation-direction: alternate;
}
