:root {
  --defualt-white: #ffffff;
  --defualt-blacK: #000000;
  --transparent: transparent;
  --color1: #3c78f0;
  --color2: #78828c;
  --color3: #f2f4f7;
  --color4: #bedcff;
  --color5: #3c414b;
  --color6: #3cc878;
  --color7: #64e49b66;
  --color8: #b4bec8;
  --width10: 10%;
  --width15: 15%;
  --width20: 20%;
  --width30: 30%;
  --width40: 40%;
  --width60: 60%;
  --width70: 70%;
  --width80: 80%;
  --width85: 85%;
  --width90: 90%;
  --fs-14: 0.875rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
}

body {
  font-family: "BwModelica-Black", sans-serif;
}

.cp {
  cursor: pointer;
}

.font-switzer {
  font-family: "Switzer", sans-serif;
}

.font-ibm {
  font-family: "IBM Plex Serif", serif;
}

/* color classes */
.color1 {
  color: var(--color1);
}

.color2 {
  color: var(--color2);
}

.color3 {
  color: var(--color3);
}

.color4 {
  color: var(--color4);
}

.color5 {
  color: var(--color5);
}

.color6 {
  color: var(--color6);
}

.color7 {
  color: var(--color7);
}

.color8 {
  color: var(--color8);
}

/* BgColor classes */
.bg-color1 {
  background-color: var(--color1);
}

.bg-color2 {
  background-color: var(--color2);
}

.bg-color3 {
  background-color: var(--color3);
}

.bg-color4 {
  background-color: var(--color4);
}

.bg-color5 {
  background-color: var(--color5);
}

.bg-color6 {
  background-color: var(--color6);
}

.bg-color7 {
  background-color: var(--color7);
}

.bg-color8 {
  background-color: var(--color8);
}

/* border classes */
.border-color1 {
  border-color: var(--color1);
}

.border-color2 {
  border-color: var(--color2);
}

.border-color3 {
  border-color: var(--color3);
}

.border-color4 {
  border-color: var(--color4);
}

.border-color5 {
  border-color: var(--color5);
}

.border-color6 {
  border-color: var(--color6);
}

.border-color7 {
  border-color: var(--color7);
}

.border-color8 {
  border-color: var(--color8);
}

/* main classes */
.text-justify {
  text-align: justify;
}

/* width classes */

.w-10 {
  width: var(--width10);
}

.w-15 {
  width: var(--width15);
}

.w-20 {
  width: var(--width20);
}

.w-30 {
  width: var(--width30);
}

.w-40 {
  width: var(--width40);
}

.w-60 {
  width: var(--width60);
}

.w-70 {
  width: var(--width70);
}

.w-80 {
  width: var(--width80);
}

.w-85 {
  width: var(--width85);
}

.w-90 {
  width: var(--width90);
}

/* button classes */
.btn-color1 {
  background: var(--color1);
  color: var(--defualt-white);
  border: 1px solid var(--color1);
}

.btn-color1:hover {
  color: var(--color1) !important;
  background: var(--defualt-white) !important;
  border: 1px solid var(--color1) !important;
}

.btn-outline-color1 {
  color: var(--color1);
  background: var(--defualt-white);
  border: 1px solid var(--color1);
}

.btn-outline-color1:hover {
  background: var(--color1) !important;
  color: var(--defualt-white) !important;
  border: 1px solid var(--color1) !important;
}

.btn-color4 {
  background: var(--color4);
  color: var(--defualt-white);
  border: 1px solid var(--color4);
}

.btn-color4:hover {
  color: var(--color4) !important;
  background: var(--defualt-white) !important;
  border: 1px solid var(--color4) !important;
}

/* font classes */
.fs-14 {
  font-size: var(--fs-14);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-24 {
  font-size: var(--fs-24);
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .border-sm-0 {
    border: none !important;
  }

  .border-sm-1 {
    border: 1px solid var(--color3) !important;
  }

  .border-sm-top {
    border-top: 1px solid var(--color3) !important;
  }

  .border-sm-top-0 {
    border-top: none !important;
  }

  .border-sm-bottom {
    border-bottom: 1px solid var(--color3) !important;
  }

  .border-sm-bottom-0 {
    border-bottom: none !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .border-md-0 {
    border: none !important;
  }

  .border-md-1 {
    border: 1px solid var(--color3) !important;
  }

  .border-md-top {
    border-top: 1px solid var(--color3) !important;
  }

  .border-md-top-0 {
    border-top: none !important;
  }

  .border-md-bottom {
    border-bottom: 1px solid var(--color3) !important;
  }

  .border-md-bottom-0 {
    border-bottom: none !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .border-lg-0 {
    border: none !important;
  }

  .border-lg-1 {
    border: 1px solid var(--color3) !important;
  }

  .border-lg-top {
    border-top: 1px solid var(--color3) !important;
  }

  .border-lg-top-0 {
    border-top: none !important;
  }

  .border-lg-bottom {
    border-bottom: 1px solid var(--color3) !important;
  }

  .border-lg-bottom-0 {
    border-bottom: none !important;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .border-xl-0 {
    border: none !important;
  }

  .border-xl-1 {
    border: 1px solid var(--color3) !important;
  }

  .border-xl-top {
    border-top: 1px solid var(--color3) !important;
  }

  .border-xl-top-0 {
    border-top: none !important;
  }

  .border-xl-bottom {
    border-bottom: 1px solid var(--color3) !important;
  }

  .border-xl-bottom-0 {
    border-bottom: none !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

@media (max-width: 768px) {
  .my-2.col-md-2 {
    display: flex;
    justify-content: space-between;
  }
}

.mentor-img {
  height: 60px;
  border-radius: 50%;
}
