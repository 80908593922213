.profile-tabs {
	background-color: none !important;
	color: #757575;
	font-size: 16px;
	line-height: 24px;
	background-color: #ffffff !important;
	padding: 10px;
	border: none;
	font-family: 'g_reg';
}

.profile-tabs.active {
	border-bottom: 2px solid var(--color16);
	color: var(--color16) !important;
	line-height: 21px;
	font-size: 16px;
	padding: 10px 0;
	font-family: 'g_mid';
}

.profile-main-layout {
	border: 1px solid #ededed;
	border-radius: 8px;
}

.upload-btn {
	font-size: 14px !important;
	line-height: 21px;
	color: #1a1a1a;
	background-color: #ffcd00;
	padding: 8px 16px !important;
	border-radius: 12px !important;
}

.upload-btn:hover {
	background-color: #ffcd00;
}

.remove-btn {
	font-size: 14px !important;
	line-height: 21px;
	color: #1a1a1a;
}

.profile-labels {
	font-size: 12px;
	line-height: 18px;
	color: #757575;
	margin-bottom: 8px;
}

.input-box-style {
	border-radius: 4px;
	padding: 12px;
	font-size: 14px !important;
	line-height: 21px;
	color: #1a1a1a;
}

/*For the following CSS to work, we need to have a placeholder inside the field, else it will not work correctly*/
.input-box-style:not(:placeholder-shown) {
	border: 1px solid var(--color16);
}

.input-date-box-style {
	border: 1px solid #757575;
	border-radius: 4px;
	padding: 9px;
	font-size: 14px !important;
	line-height: 21px;
	color: #1a1a1a;
}
.form-control:focus {
	border-color: #000000 !important;
}

.dropdown-item {
	width: 94%;
	padding: 8px 12px;
	margin-left: 10px !important;
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
	border-radius: 4px;
}

.dropdown-item:hover {
	background-color: #f1f1f1 !important;
	padding: 8px 12px;
	margin-left: 5px;
}

.dropdown-item:active {
	color: #1a1a1a;
}

.save-changes-btn {
	background-color: #ffcd00;
	padding: 8px 26px;
	color: #1a1a1a;
	font-size: 16px;
	line-height: 24px;
	border-radius: 12px;
	border: none;
	margin: 10px 0;
}

.v-text {
	font-size: 12px;
	line-height: 14px;
	color: #3c78f0;
}

.check-ui {
	background-color: #22c03c;
}

.veri-text {
	font-size: 11px;
	line-height: 12px;
	color: #1a1a1a;
	border-radius: 60px;
	padding: 4px 6px;
	background-color: #f1f1f1;
}
.react-date-picker__wrapper {
	border: none;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none !important;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
	font-size: 28px;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
	font-family: 'g_mid';
}
.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none;
	cursor: default;
}
.react-calendar__month-view__weekdays__weekday {
	font-size: var(--fs-12);
	color: var(--color17);
}
/* .react-calendar__tile--now {
  background-color: var(--color15);
  border-radius: 80px;
} */
.react-date-picker__calendar,
.react-calendar {
	width: 100%;
	border-radius: 8px;
	max-width: 300px;
	min-width: 250px;
}
/* .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth, */
.react-calendar__tile.react-calendar__month-view__days__day {
	font-size: var(--fs-12);
	/* color: var(--color17); */
	color: var(--color17);
	font-family: 'p_mid';
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
	color: var(--white);
}

.react-calendar__tile.react-calendar__month-view__days__day {
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
	color: var(--color17) !important;
	/* font-family: "g_semi"; */
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
	background-color: var(--color15) !important;
	color: var(--color16) !important;
	font-family: 'g_semi';
	border-radius: 50%;
}
.react-calendar__tile--now {
	background: none !important;
	color: inherit !important;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
	color: var(--white) !important;
}
.react-date-picker__inputGroup__input:invalid {
	background: transparent;
}
.react-calendar__tile:enabled:hover {
	background-color: var(--color15);
	border-radius: 50%;
}
@media only screen and (max-width: 400px) {
	.upload-btn {
		font-size: 12px !important;
	}
}
