@use "../core/variable" as var;

.modal-header {
  border-bottom: none !important;
  padding: 1.4rem !important;
  padding-bottom: 0.5rem !important;
}
#info-block section {
  border-top: 1px solid var.$light-white;
}
.file-marker > div {
  padding: 0 4px;
  margin-top: -0.8em;
}
.login-separator {
  background: white none repeat scroll 0 0;
  display: inline-block;
  padding: 0 2px;
  margin-left: 13em;
}
@media (max-width: 767px) {
  .login-separator {
    margin-left: 10em;
  }
}
@media (max-width: 320px) {
  .login-separator {
    margin-left: 7em;
  }
}
